<template>
  <section class="card-section" v-if="payload">
    <h4 v-html="index + '. ' + $t(`form.complaints.${title}`).toUpperCase()" />
    <section
      v-for="field in section"
      :key="field.id"
      :class="field.type + '-field'"
    >
      <AppInput
        v-if="field.type == 'input'"
        v-model:error="field.error"
        v-model:type="field.typeInput"
        :title="`complaints.${field.title}`"
        v-model:value="payload[field.title]"
        :disabled="field.disabled"
      />
      <AppSelect
        v-else-if="field.type == 'select'"
        v-model:error="field.error"
        :title="`complaints.${field.title}`"
        v-model:value="payload[field.title]"
        attr="description"
        :options="payload[field.options]"
        placeholder="selectOne"
        :disabled="field.disabled"
      />
      <AppAutoComplete
        v-else-if="field.type == 'autocomplete'"
        v-model:error="field.error"
        :title="`complaints.${field.title}`"
        v-model:value="payload[field.title]"
        :disabled="field.disabled"
        :apiField="field.title"
      />
      <AppTextArea
        v-else-if="field.type == 'textarea'"
        v-model:error="field.error"
        :title="`complaints.${field.title}`"
        v-model:value="payload[field.title]"
        :disabled="field.disabled"
      />
      <AppAttachments
        v-else-if="field.type == 'attachments'"
        v-model:error="field.error"
        :title="`complaints.${field.title}`"
        :value="payload[field.title]"
        @update:value="updateComplaint"
        :disabled="
          payload.status == 3 || payload.status == 5 || (user && user.role)
        "
      />
      <AppTable
        v-else-if="field.type == 'table'"
        v-model:error="field.error"
        :selected="payload['typeId']"
        @update:selected="updateComplaint"
        attr="id"
        v-model:items="types"
        :tableHeaders="tableHeaders"
        :disabled="field.disabled"
      />
      <p
        v-else-if="field.type == 'text'"
        v-html="$t(`form.complaints.${field.title}`)"
      />
      <Checkbox
        v-else-if="field.type == 'checkbox'"
        :title="`complaints.${field.title}`"
        :label="$t(`acceptPolicy.accept`)"
        :disabled="field.disabled"
        v-model:error="field.error"
        :value="payload[field.title]"
        @update:value="updateComplaint"
      />
      <AppExpand
        v-else
        :title="`complaints.${field.title}`"
        v-model:error="field.error"
        ref="issuerData"
        :value="payload"
        @update:value="updateComplaint"
      />
    </section>
    <p class="error-password" />
    <section class="buttons-section">
      <AppButton
        v-if="totalSections && index > 1"
        @click="callback('page', -1)"
        title="prev"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
      <AppButton
        title="next"
        v-if="index < totalSections"
        @click="callback('page', 1)"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
      <AppButton
        title="transact"
        v-if="index === totalSections"
        @click="callback('send', payload)"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
    </section>
  </section>
</template>

<script>
import { computed, ref } from "vue";
import AppExpand from "../app-expand/expand.vue";
import AppInput from "../app-input/input.vue";
import AppSelect from "../app-select/select.vue";
import AppTextArea from "../app-textarea/textarea.vue";
import AppTable from "../app-paginated-table/app-table.vue";
import AppAttachments from "../app-attachments/attachments.vue";
import Checkbox from "../app-checkbox/app-checkbox.vue";
import AppButton from "../app-button/button.vue";
import { useStore } from "vuex";
import AppAutoComplete from "../app-autocomplete/autocomplete.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "AppFormSection",
  props: ["section", "title", "index", "totalSections"],
  components: {
    AppInput,
    AppSelect,
    AppTextArea,
    AppTable,
    AppButton,
    AppAttachments,
    AppAutoComplete,
    AppExpand,
    Checkbox,
  },
  setup(props, { emit }) {
    const store = useStore();
    const payload = computed(() => store.getters["Setting/getComplaint"]);
    const issuerData = ref(null);

    const { tm } = useI18n();
    const types = computed(() => store.getters["Setting/typeState"]);

    const updateComplaint = (newFields) => {
      store.dispatch("Setting/setComplaint", {
        ...payload.value,
        ...newFields,
      });
    };

    function callback(action, arg) {
      if (!verifyFields() || arg < 0) {
        switch (action) {
          case "page":
            props.section.forEach((el, i) => {
              store.dispatch("Setting/errorDataAction", {
                index: i,
                attr: props.title,
                data: false,
              });
            });
            store.dispatch("Setting/setComplaint", payload.value);
            emit("page", arg);
            break;
          case "send":
            if (checkPassword()) emit("send", arg);
            break;
        }
      }
    }

    const checkPassword = () => {
      var p = document.getElementsByClassName("error-password")[0];
      if (
        (payload.value.password &&
          payload.value.repeatPassword &&
          payload.value.password !== payload.value.repeatPassword) ||
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(
          payload.value.password
        )
      ) {
        p.innerHTML = `<div>${tm(`app.passwordPolicies`)}</div>
        <div>${tm(`app.passwordRestrictions`)}</div>
                      <div> ${tm(`app.passwordCoincidence`)}</div>`;
      } else return true;
    };
    //TO DO: Errors control
    const verifyFields = () => {
      let error = false;

      props.section.forEach((element, i) => {
        if (element.mandatory) {
          if (!payload.value[element.title]) {
            store.dispatch("Setting/errorDataAction", {
              index: i,
              attr: props.title,
              data: true,
            });
            error = true;
          } else {
            store.dispatch("Setting/errorDataAction", {
              index: i,
              attr: props.title,
              data: false,
            });
          }
        }
        if (
          element.title == "anonymous" &&
          Object.keys(payload.value).filter(
            (key) => key.includes("issuer") && payload.value[key]
          ).length > 0
        ) {
          error = issuerData.value[0].checkIssuerData();
        }
      });
      return error;
    };
    function checked(status) {
      privacyPolitic = status;
    }
    return {
      updateComplaint,
      payload,
      issuerData,
      route: useRoute(),
      user: JSON.parse(sessionStorage.getItem("userConnected")),
      callback,
      checked,
      tableHeaders: [
        { key: "type", class: 1 },
        { key: "description", class: 3 },
      ],
      types,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./form-section.scss";
</style>
