
export default {
    $digitalisGreen: '#00B1BC',
    $neonGreen52: "#049294",
    $neonGreen: '#07EFEF',
    $blackGreen: '#012A2D',
    $mediumGreen: '#048C8E',
    $white: '#FFFFFF',
    $lightPink: '#FFCADE',
    $blue: '#00838f',
    $lightBlue: '#66B5bc',
    $neonPink: '#FC0369',
    $lightViolet: '#9681b2',
    $violet:'#4f2d7f',
    $red:'#de002e',
    $lightRed:'#eb6681',
    $grey:'#66666B',
    $gray:'#beb4aa',
    $lightGray:'#eae7e4'
}