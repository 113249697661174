import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import localeCat from "./locales/cat.json";
import localeSp from "./locales/sp.json";
import localeEn from "./locales/en.json";

if (!localStorage.Lang) {
  localStorage.Lang = process.env.VUE_APP_LANG;
}

export const i18n = createI18n({
  legacy: false,
  locale: localStorage.Lang,
  fallbackLocale: process.env.VUE_APP_LANG,
  messages: {
    sp: localeSp,
    cat: localeCat,
    en: localeEn,
  },
});

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(store);
app.use(require("moment"));
app.mount("#app");
