<template>
  <router-view v-slot="{ Component }">
    <transition :name="'page'" appear>
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { onBeforeMount } from "vue";
import { initializeStorage } from "./services/general";

export default {
  name: "App",
  setup() {
    onBeforeMount(async () => await initializeStorage());
  },
};
</script>

<style lang="scss">
@import url("./assets/styles/styles.scss");

@font-face {
  font-family: "GTWalsheimPro";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/GTWalsheimPro-Regular.ttf");
}

@font-face {
  font-family: "GTWalsheimProMedium";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/GTWalsheimPro-Medium.ttf");
}
@font-face {
  font-family: "GTWalsheimProBold";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/GTWalsheimPro-Bold.ttf");
}
@font-face {
  font-family: "amethyst";
  src: url("./assets/fonts/Amethyst.ttf");
  font-weight: normal;
  font-style: normal;
}

#app {
  font-family: "GTWalsheimPro" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  button,
  textarea {
    font-family: "GTWalsheimPro" !important;
  }
}

#nav {
  padding: 30px;
  overflow-x: hidden;
  a {
    font-weight: bold;
    color: white;
  }
}
.router-enter-active,
.router-leave-active {
  transition: all 0.75s ease;
}
.router-enter-from {
  transform: translateX(-1vw);
  opacity: 0;
} //ENTRAMOS DESDE -25vw
.router-enter-to {
  transform: translateX(0vw);
  opacity: 1;
} // HASTA 0vw
.router-leave-from {
  transform: translateX(-80vw);
} //SALIMOS DESDE 0vw
.router-leave-to {
  transform: translateX(-80vw);
} //HASTA 25vw
</style>
