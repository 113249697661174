<template>
  <section class="container-notifications">
    <Icon
      type="bell"
      :width="2"
      color="primary"
      style="cursor: pointer; margin-top: -3px"
      @click="handleTapIcon"
    />
    <p v-if="notifications.length > 0" class="badge-notification" />
    <ul v-if="showNotifications" class="notifications">
      <li
        v-for="notification in notifications"
        :key="notification"
        @click="redirectTo(notification)"
      >
        <p v-html="notification.notificationReason" />
        <p v-html="moment.utc(notification.createdAt).tz(currentTimezone).format('DD/MM/YYYY HH:mm')"/>
      </li>
    </ul>
  </section>
</template>
<script>
import { ref, onMounted } from "vue";
import { getAllNotifications, readNotification } from "../../services/user";
import Icon from "../../components/common/app-icon/icon.vue";
import moment from "moment";
import { useRouter } from "vue-router";
require('moment-timezone');
const currentTimezone = moment.tz.guess();
export default {
  name: "Notfications",
  components: { Icon },
  setup() {
    const showNotifications = ref(false);
    let notifications = ref([]);
    const user = JSON.parse(sessionStorage.getItem("userConnected"));
    const router = useRouter();
    onMounted(() => {
      getNotifications();
      window.onclick = closeModal;
    });

    const closeModal = (event) => {
      const modal = document.getElementsByClassName(
        "container-notifications"
      )[0];
      if (modal && modal.contains(event.target)) {
        showNotifications.value = true;
      } else showNotifications.value = false;
    };

    const handleTapIcon = () => {
      showNotifications.value = !showNotifications.value;
      getNotifications();
    };
    const getNotifications = async () => {
      const currentTimezone = moment.tz.guess();
      if (user && user.role){
        notifications.value = await getAllNotifications({
          email: user.user,
          offset: 0,
          limit: 10000000000,
        });
    }
    };

    const redirectTo = async (notification) => {
      if (notification !== "/home") {
        await readNotification({
          notificationId: notification.notificationId,
          userId: user.user,
        });
        router.push(
          `/admin/complaint/${notification.complaintId}/information-complaint`
        );
        setTimeout(() => {
          location.reload();
        }, 100);
        showNotifications.value = false;
      } else {
        router.push("/admin/init");
      }
    };

    return {
      handleTapIcon,
      showNotifications,
      notifications,
      moment: moment,
      redirectTo,
      getNotifications,
      currentTimezone
    };
  },
};
</script>

<style lang="scss" scoped>
.container-notifications {
  position: relative;
  .badge-notification {
    border-radius: 50%;
    padding: 4px;
    background-color: red;
    position: absolute;
    top: 20px;
    right: 0;
  }
  .notifications {
    color: var(--mq-header);
    background-color: var(--mq-white);
    z-index: 1;
    right: 0;
    max-height: 40vh;
    overflow: auto;
    padding: 0;
    position: absolute;
    list-style: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    li {
      cursor: pointer;
      width: 20em;
      border-bottom: 1px solid var(--mq-gray-light);
      padding: 1em 0.5em;
      font-size: 0.8em;
      transition: all 0.5s ease;

      &:hover {
        background-color: var(--mq-gray);
      }
    }
  }
}
</style>
