<template>
  <section id="header-menu">
    <img class="main-menu-img" :src="getLogo(0)" />
    <img class="main-menu-img" :src="getLogo(1)" />
    <img class="main-menu-img" :src="getLogo(2)" />
    <section class="header-user-menu">
      <div v-if="allow" style="min-width: fit-content" v-html="user.user" />
      <Icon
        style="cursor: pointer"
        v-if="allow && user.role && user.role === 'ADMIN'"
        type="setting"
        :width="2"
        color="primary"
        @click="callback('change')"
      />
      <Notifications v-if="allow" />
      <Languages />
      <Icon
        style="cursor: pointer"
        :title="$t('login.registeredUser')"
        v-if="allow || (!allow && !route.meta.auth)"
        type="logout"
        :width="2"
        color="primary"
        @click="callback('logout', allow)"
      />
      <section
        v-if="!allow && route.meta.auth"
        class="comeback-button"
        @click="callback('logout', true)"
      >
        <h5 v-html="$t(`app.comeBack`)" />
        <Icon type="back" :width="1.5" color="primary" />
      </section>
    </section>
    <section class="main-menu" v-if="allow">
      <router-link
        v-for="section in menu"
        :key="section.id"
        :to="section.url"
        :class="[
          'section-item',
          { 'router-link-active': section.url === route.path },
        ]"
        >{{ $t(`app.menu.${section.name}`) }}
      </router-link>
    </section>
  </section>
  <AppModal
    :showModal="showModal[0]"
    @close="showModal[0] = false"
    :width="45"
    :height="45"
  >
    <template #header>
      <h2 v-html="$t(`modal.modify`)" />
    </template>
    <template #body>
      <AppMultiSelect
        style="margin-top: 2vh"
        v-if="
          userData['role'] &&
          (userData.role !== 'ENTITY' ||
            userData.role !== 'ADMINENTITY' ||
            userData.role !== 'MANAGERENTITY')
        "
        placeholder="selectOne"
        title="user.entities"
        attr="entityId"
        name="entity"
        :options="entities"
        v-model:value="userData['entities']"
      />
      <section class="modal-body-user">
        <AppInput
          v-if="userData.role < roles.length"
          title="user.name"
          v-model:value="userData['name']"
        />
        <AppInput
          v-if="userData.role < roles.length"
          title="user.firstFamilyName"
          v-model:value="userData['firstFamilyName']"
        />
        <AppInput
          v-if="userData.role == roles.length"
          title="user.entityName"
          v-model:value="userData['entity']"
        />
        <!-- <AppInput title="user.email" v-model:value="userData['email']" /> -->
        <AppInput
          v-if="userData.role < roles.length"
          title="user.telephone"
          v-model:value="userData['telephone']"
        />
        <!--<AppAutocomplete v-if="userData.role==roles.length" placeholder="selectOne" title="user.country" apiField="country" v-model:value="userData['country']"/>-->
        <AppMultiInput
          v-if="userData.role == 'ENTITY' || userData.role == 'ADMINENTITY'"
          placeholder="writeSite"
          title="user.sites"
          attr="id"
          v-model:value="userData['sites']"
        />
        <AppButton
          v-if="userData.role == roles.length"
          title="attach"
          type="upload"
          @click="callback('filter')"
          :color="{ color: 'thirty', hover: 'thirty' }"
        />
      </section>
    </template>
    <template #footer>
      <AppButton
        title="modify"
        @click="callback('modify')"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
      <AppButton
        title="cancel"
        @click="showModal[0] = false"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
    </template>
  </AppModal>
  <AppModal
    :showModal="showModal[1]"
    @close="showModal[1] = false"
    :width="32"
    :height="20"
  >
    <template #header>
      <h2 v-html="$t('login.userPassword')" />
    </template>
    <template #body>
      <AppInput 
        title="login.user" 
        v-model:value="userData['user']"
        />
      <AppInput
        title="login.password"
        v-model:type="type"
        v-model:value="userData['password']"
      />
      <div
        class="link-password"
        v-html="$t('login.forgotPassword')"
        @click="
          showModal[1] = false;
          showModal[2] = true;
        "
      />
    </template>
    <template #footer>
      <AppButton
        title="access"
        @click="callback('login')"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
      <AppButton
        title="cancel"
        @click="showModal[1] = false"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
    </template>
  </AppModal>
  <AppModal
    :showModal="showModal[2]"
    @close="showModal[2] = false"
    :width="32"
    :height="20"
  >
    <template #header>
      <h2 v-html="$t('login.reset')" />
    </template>
    <template #body>
      <div v-html="$t('login.resetPassword')" style="marginbottom: 1em" />
      <AppInput title="login.user" v-model:value="userData['user']" />
    </template>
    <template #footer>
      <AppButton
        title="reset"
        @click="callback('reset')"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
      <AppButton
        title="cancel"
        @click="showModal[2] = false"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
    </template>
  </AppModal>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Icon from "../../components/common/app-icon/icon.vue";
import AppModal from "../../components/common/modal/modal.vue";
import AppSelect from "../../components/common/app-select/select.vue";
import AppMultiSelect from "../../components/common/app-multiselect/multiselect.vue";
import AppMultiInput from "../../components/common/app-multi-input/multi-input.vue";
import AppButton from "../../components/common/app-button/button.vue";
import AppInput from "../../components/common/app-input/input.vue";
import { login, reset } from "@/services/auth";
import { response } from "@/services/general";
import { createUpdateUser, getUser } from "../../services/user";
import Notifications from "../../components/notifications/notifications.vue";
import Languages from "../../components/language/language.vue";

export default {
  components: {
    Icon,
    AppModal,
    Notifications,
    Languages,
    AppSelect,
    AppButton,
    AppInput,
    AppMultiInput,
    AppMultiSelect,
  },
  name: "Header",
  props: ["allow"],
  setup(_, { emit }) {
    const router = useRouter();
    const store = useStore();
    let type = ref("password");
    const user = JSON.parse(sessionStorage.getItem("userConnected"));
    const entities = computed(() => store.getters[`Setting/getEntities`]);
    let showModal = ref([false, false]);

    let userData = ref({});

    const menu = computed(() => store.getters[`Setting/menuState`][user.role]);
    const logos = [
      computed(() => store.getters[`Setting/logo1State`]),
      computed(() => store.getters[`Setting/logo2State`]),
      computed(() => store.getters[`Setting/logo3State`])
    ]

    const getLogo = (index) => {
      var img = require.context("../../assets/images/");
      var logo = logos[index].value;

      if (logo === 'undefined') {
        return undefined;
      }

      //if (!router.currentRoute.value.path.includes("sign-in"))
      return img(`./${logo}`);
    };

    const callback = async (action, payload) => {
      let status;
      switch (action) {
        case "change":
          userData.value = await getUser({ id: user.user });
          showModal.value[0] = true;
          break;
        case "logout":
          if (payload) logout();
          else {
            userData.value = {};
            showModal.value[1] = true;
          }
          break;
        case "login":
          status = await login({ userConnected: userData.value });
          if (status.toString().startsWith(2)) router.push("/admin/init");
          else if (status.toString() === "400") {
            showModal.value[1] = false;
            emit("popup", "passwordExpired");
          } else if (status.toString() === "401") {
            showModal.value[1] = false;
            emit("popup", "wrongLogin");
          } else {
            showModal.value[1] = false;
            emit("popup", "internalError");
          }
          
          break;
        case "reset":
          status = await reset({ user: userData.value });
          showModal.value[2] = false;
          response(status, null, "reset", emit);
          break;
        case "modify":
          status = await createUpdateUser({
            id: user.user,
            request: userData.value,
          });
          showModal.value[0] = false;
          response(status, null, "modify", emit);
          break;
      }
    };

    const logout = async () => {
      sessionStorage.removeItem("authToken");
      sessionStorage.removeItem("userConnected");
      await store.dispatch(`Setting/setComplaint`, undefined);
      router.push("/");
    };

    return {
      callback,
      menu,
      showModal,
      user,
      userData,
      type,
      entities,
      getLogo,
      roles: store.getters[`Setting/roleState`],
      route: useRoute(),
    };
  },
};
</script>
<style scoped lang="scss">
#header-menu {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  height: fit-content;
  justify-content: space-between;
  transition: all 0.25s ease;

  .main-menu-img {
    max-height: 15em;
    min-height: 3em;
    max-width: 15em;
    margin: 1vh 1vw;
    max-height: 7vh;
  }

  .header-user-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 1vw;
    flex-grow: 1;
    margin: 0 1vw;
    align-items: center;
    min-height: 7vh;
  }

  .main-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: var(--mq-primary-light);
    padding: 4vh 5vw 0 7vw;
    width: 100%;
    height: 7vh;

    .section-item {
      font-size: 2vh;
      font-weight: lighter;
      text-align: center;
      text-transform: uppercase;
      flex: 1;
      cursor: pointer;

      &:hover {
        @extend .router-link-active;
      }
    }

    .router-link-active {
      transition: all 0.1s ease !important;
      border-bottom: 5px solid var(--mq-primary);
    }
  }
}

.comeback-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}

.link-password {
  text-decoration: underline;
  color: var(--mq-primary);
  cursor: pointer;
}

.page-enter-active,
.page-leave-active {
  transition: opacity 1s, transform 1s;
}

.page-enter,
.page-leave-to {
  opacity: 0;
  transform: translateX(-5%);
}
</style>
