<template>
  <section class="autocomplete">
    <h5 v-html="$t(`form.${title}`)" />
    <input
      type="search"
      v-model="input"
      :disabled="disabled"
      :class="{ 'input-error': error }"
    />
    <ul
      class="results-autocomplete"
      :style="{ opacity: options.length > 0 ? '1' : '0' }"
    >
      <li
        class="item-result"
        @click="selectItem(element)"
        v-for="element in options"
        :key="element.id"
        v-html="element[apiField] || element.name"
      />
    </ul>
  </section>
  <section
    v-if="error"
    style="
      color: var(--mq-secondary);
      display: inline-flex;
      margin: 0.5vh 0.25vw;
    "
  >
    <Icon type="alert" :width="1" :color="'secondary'" />
    {{ $t("form.verify") }}
  </section>
</template>

<script>
import { assets } from "../../../services/general";
import { watch, ref } from "vue";
import Icon from "../app-icon/icon.vue";
import { i18n } from "../../../main";
export default {
  name: "AutocompleteField",
  components: { Icon },
  props: ["title", "value", "apiField", "disabled", "error"],
  setup(props, { emit }) {
    let options = ref([]);
    let selected = ref(false);
    let input = ref(props.value ? props.value[props.apiField] : null);
    
    watch(
      () => props.value,
      () => {
        if (props.value) {
          input.value = props.value[props.apiField];
        }
      }
    );

    watch(
      () => input.value,
      async (val) => {
        if (val.length > 0) {
          var response = await assets({
            apiField: props.apiField,
            input: val,
            lan: i18n.global.locale.value,
          });
          if (
            response.filter((option) => option[props.apiField] === val)
              .length == 0
          )
            options.value = response;
          else options.value = [];
        } else if (val === "") {
          options.value = [];
          selected.value = false;
        }
      }
    );

    const selectItem = (element) => {
      emit("update:value", element);
      options.value = [];
    };

    return {
      options,
      input,
      selectItem,
    };
  },
};
</script>

<style lang="scss">
@import "./autocomplete.scss";
</style>
