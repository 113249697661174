<template>
<div class="switch-component">
<div class="name-switch-button" :class="{'enable-name':!enabled}" v-html="$t(`app.switchButtonMessage1`)" />
<div class="switch-button-control">
    <div class="switch-button" :class="{ 'enabled': enabled }" @click="toggle" :style="{'--color': color}">
      <div class="button"></div>
    </div>
  </div>
  <div class="name-switch-button" :class="{'enable-name':enabled}" v-html="$t(`app.switchButtonMessage2`)" />
</div>
</template>

<script>


export default {
  name: 'AppButtonSwitch',
  model: {
    prop: "isEnabled",
    event: "toggle"
  },
  data() {
    return {
      enabled: this.isEnabled
    }
  },
  props: {
    isEnabled:{
        default: false
    },
    color: {
      default: '#4f2d7f'
    }
  },
  methods: {
    toggle() {
      this.enabled = !this.enabled;
      this.$emit('toggle', !this.enabled);
    }
  },
}
</script>

<style lang="scss">

.switch-component{
  display: flex;
  flex-direction: row;
  transition: all 1s ease !important;

  .name-switch-button {
    margin: 0.5vh .5vw 0 .5vw;
    font-size:1.5vh;
  }
  .enable-name {
    color: var(--mq-primary);
    font-weight: bold;
    font-size:2vh;
    transform: translateY(-0.5vh)

  }

.switch-button-control {
  display: flex;
  flex-direction: row;
  margin-top: 0;
  
  .switch-button {
    $switch-button-height: 1.2em;
    $switch-button-color: var(--color);
    $switch-button-border-thickness: 2px;
    $switch-transition: all 0.3s ease-in-out;
    $switch-is-rounded: true;

    height: $switch-button-height;
    width: calc(#{$switch-button-height} * 2);
    border: $switch-button-border-thickness solid $switch-button-color;
    box-shadow: inset 0px 0px $switch-button-border-thickness 0px
      rgba(0, 0, 0, 0.33);
    border-radius: if($switch-is-rounded, $switch-button-height, 0);

    transition: $switch-transition;

    $button-side-length: calc(
      #{$switch-button-height} - (2 * #{$switch-button-border-thickness})
    );
  
    cursor: pointer;

    .button {
      height: $button-side-length;
      width: $button-side-length;
      border: $switch-button-border-thickness solid $switch-button-color;
      border-radius: if($switch-is-rounded, $button-side-length, 0);
      background: $switch-button-color;
      transform: translateX(-0.06vw);
      transition: $switch-transition;
    }

    &.enabled {
      background-color: $switch-button-color;
      box-shadow: none;

      .button {
        background: white;
        transform: translateX(
          calc(#{$button-side-length} + (2 *#{$switch-button-border-thickness}))
        );
      }
    }
  }

}
}
</style>
