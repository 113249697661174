<template>
  <section
    v-if="type !== 'upload'"
    class="button-app"
    :style="{
      '--color': 'var(--mq-' + color.color + ')',
      '--color-hover': 'var(--mq-' + color.hover + ')',
    }"
  >
    <div v-html="$t(`button.${title}`)" />
  </section>
  <label v-else @change="$emit('callback', $event)"
    ><input type="file" />
    <section
      :class="type"
      :style="{
        '--color': 'var(--mq-' + color.color + ')',
        '--color-hover': 'var(--mq-' + color.hover + ')',
        '--color-font': 'var(--mq-' + color.font + ')',
      }"
    >
      <div>{{ $t(`button.${title}`) }}</div>
      <Icon
        style="height: 100% !important"
        :type="type"
        :color="color.font"
        :width="2"
        :padding="0.5"
        :background="color.hover"
      />
    </section>
  </label>
</template>
<script>
import Icon from "../app-icon/icon.vue";
export default {
  name: "AppButton",
  components: { Icon },
  props: ["title", "color", "type"],
};
</script>

<style lang="scss" scoped>
@import "./button.scss";
</style>
