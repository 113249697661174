import axios from 'axios'

const token = document.head.querySelector('meta[name="csrf-token"]') || ''
export default axios.create({
  baseURL : `${process.env.VUE_APP_BASE_URL}`,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': token
  }
})
