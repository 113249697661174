import { createRouter, createWebHistory } from "vue-router";

/*Layouts*/
import MainLayout from "../layouts/MainLayout";
import AuthLayout from "../layouts/AuthLayout";
import BlankLayout from "../layouts/BlankLayout";

/*Authentic Views*/
import Login from "../views/login/login.vue";

/*App Views*/
import Init from "../views/admin/init.vue";
import Users from "../views/admin/users.vue";
import Complaints from "../views/admin/complaint/complaints.vue";
import Assignation from "../views/admin/complaint/assignation.vue";
import Resolution from "../views/admin/complaint/resolution.vue";

import NewComplaint from "../views/home/new-complaint.vue";
import SumCode from "../views/home/sum-code.vue";

// LOGIN
const authRoutes = (prop) => [
  {
    path: "sign-in",
    name: prop + ".sign-in",
    meta: { auth: false },
    component: Login,
  },
];

// ROUTES PUBLIC APP. Create complaint and track complaint by informer
const publicRoutes = (prop) => [
  {
    path: "new-complaint",
    name: prop + ".new-complaint",
    meta: { auth: true, name: "NewComplaint" },
    component: NewComplaint,
  },
  {
    path: "new-complaint/sum-code",
    name: prop + ".new-complaint.sum-code",
    meta: { auth: true, name: "SumCode" },
    component: SumCode,
  },
  {
    path: "track-complaint/:id",
    name: prop + ".track-complaint",
    meta: { auth: true, name: "TrackComplaint" },
    component: Resolution,
  },
  {
    path: "track-complaint/:id/complaint-data",
    name: prop + ".track-complaint.complaint-data",
    meta: { auth: true, name: "ComplaintData", comeBack: true },
    component: Assignation,
  },
];

// APP ROUTES (PRIVATE SITE)
const appRoutes = (prop) => [
  {
    path: "init",
    name: prop + ".init",
    meta: { auth: true, name: "Init" },
    component: Init,
  },
  {
    path: "users",
    name: prop + ".users",
    meta: { auth: true, name: "Users" },
    component: Users,
  },
  {
    path: "complaint",
    name: prop + ".complaint",
    meta: { auth: true },
    component: Complaints,
  },
  {
    path: "complaint/:id/assignation",
    name: prop + "complaint.assignation",
    meta: { auth: true, comeBack: true },
    component: Assignation,
  },
  {
    path: "complaint/:id/information-complaint",
    name: prop + "complaint.information",
    meta: { auth: true, comeBack: true },
    component: Resolution,
  },
  {
    path: "complaint/:id/resolution",
    name: prop + "complaint.resolution",
    meta: { auth: true, comeBack: true },
    component: Resolution,
  },
];

// ROUTES FOR THE APP
const routes = [
  {
    path: "/cert",
    name: "login",
    component: AuthLayout,
    meta: { auth: true },
    children: authRoutes("cert"),
  },
  {
    path: "/home",
    name: "home",
    component: BlankLayout,
    meta: { auth: true },
    children: publicRoutes("home"),
  },
  {
    path: "/",
    name: "",
    component: AuthLayout,
    meta: { auth: true },
    children: authRoutes(""),
  },
  {
    path: "/admin",
    name: "admin",
    component: MainLayout,
    meta: { auth: true, transition: "page" },
    children: appRoutes("admin"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

// SECURITY FOR ROUTING WITHOUT TOKEN
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/cert/sign-in",
    "/home/new-complaint",
    /*'/admin/complaint','/admin/users', */ `/home/new-complaint/sum-code` /*`/admin/complaint/${to.params.id}/resolution`, `/admin/complaint/${to.params.id}/information-complaint`,*/ /*`/home/track-complaint/${to.params.id}`, `/home/track-complaint/${to.params.id}/complaint-data`,/* `/admin/complaint/${to.params.id}/assignation`*/,
  ];
  if (publicPages.includes(to.path)) {
    sessionStorage.removeItem("userConnected");
    sessionStorage.removeItem("authToken");
  }
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem("authToken");
  if (to.meta.auth) {
    if (authRequired && loggedIn === null) return next("/cert/sign-in");
  }
  next();
});

export default router;
