<template>
  <section class="multi-select">
    <h5 class="title" v-if="title" v-html="$t(`form.${title}`)" />
    <div
      :class="['select-customize', { 'select-error': error }]"
      @click="expand = !expand"
    >
      {{ $t(`form.${placeholder}`) }}
      <div class="icon-input">
        <Icon
          :style="{ transform: expand ? 'rotate(0deg)' : 'rotate(180deg)' }"
          type="arrow"
          :width="0.75"
        />
      </div>
    </div>
    <ul v-if="expand">
      <li v-html="$t('app.selectAll')" @click="disabled ? null : selectAll()" />
      <li
        v-for="option in options"
        :key="option.id"
        @click="disabled ? null : selectOption(option)"
      >
        {{ option[name] }}
        <section
          :style="{
            opacity: isSelected(option) ? 1 : 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '3vw',
          }"
        >
          <Icon type="check" color="thirty" :width="0.75" />
        </section>
      </li>
    </ul>
    <section
      v-if="error"
      style="
        color: var(--mq-secondary);
        display: inline-flex;
        margin: 0.5vh 0.25vw;
      "
    >
      <Icon type="alert" :width="1" :color="'secondary'" />
      {{ $t("form.verify") }}
    </section>
  </section>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import Icon from "../app-icon/icon.vue";

export default {
  name: "Multiselect",
  props: [
    "value",
    "width",
    "options",
    "placeholder",
    "title",
    "error",
    "type",
    "attr",
    "name",
    "disabled",
    "canRemove",
    "originalOptions",
  ],
  components: { Icon },
  setup(props, { emit }) {
    let expand = ref(false);
    let optionSelected = ref(props.value || []);

    onMounted(() => {
      addValue();
    });
    watch(
      () => props.value,
      () => {
        optionSelected.value = props.value || [];
      }
    );

    function addValue() {
      if (!!props.value && props.value.length > 0) {
        if (
          JSON.stringify(props.value) !== JSON.stringify(optionSelected.value)
        ) {
          props.value.forEach((el) => {
            var option = props.options.filter(
              (opt) => opt[props.attr] === el[props.attr]
            )[0];
            selectOption(option);
          });
        }
      }
    }

    function isSelected(option) {
      if (option) {
        if (
          optionSelected.value.findIndex(
            (item) => item[props.attr] === option[props.attr]
          ) === -1
        )
          return false;
        else return true;
      }
    }

    function selectOption(option) {
      if (option) {
        var index = optionSelected.value.findIndex(
          (item) => item[props.attr] === option[props.attr]
        );
        if (index === -1) {
          optionSelected.value.push(option);
        }
        else if (props.canRemove || !props.originalOptions.some((item) => item[props.attr] === option[props.attr])) {
          optionSelected.value.splice(index, 1);
        }
        emit("update:value", optionSelected.value);
      }
    }

    function selectAll() {
      optionSelected.value = props.options.map((option) => option);
      emit("update:value", optionSelected.value);
    }

    return {
      selectOption,
      selectAll,
      expand,
      isSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./multiselect.scss";
</style>
