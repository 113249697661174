<template>
  <section class="input">
    <h5 v-if="title" v-html="$t(`form.${title}`)" />
    <section>
      <input
        :class="{ 'input-error': error }"
        :disabled="disabled"
        @keyup.enter="$emit('keyup')"
        :placeholder="placeholder ? $t(`form.${placeholder}`) : ''"
        :value="value"
        @update:value="value"
        :type="typeInput"
        autocomplete="new-password"
        @change="$emit('update:value', $event.target.value)"
      />
      <Icon
        v-if="type === 'password'"
        style="position: absolute; top: 3.5vh; right: 0.75vw; cursor: pointer"
        type="eye"
        :width="1"
        color="primary"
        @click="visibility"
      />
    </section>
    <section
      v-if="error"
      style="
        color: var(--mq-secondary);
        display: inline-flex;
        margin: 0.5vh 0.25vw;
      "
    >
      <Icon type="alert" :width="1" :color="'secondary'" />
      {{ $t("form.verify") }}
    </section>
  </section>
</template>
<script>
import Icon from "../app-icon/icon.vue";
import { ref } from "vue";

export default {
  name: "AppInput",
  props: ["title", "placeholder", "value", "disabled", "type", "error"],
  components: { Icon },
  setup(props) {
    const typeInput = ref(props.type || "text");

    const visibility = () => {
      typeInput.value = typeInput.value === "text" ? "password" : "text";
    };

    return {
      typeInput,
      visibility,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./input.scss";
</style>
