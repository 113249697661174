<template>
  <section>
    <Loader />
    <Header
      :allow="true"
      @popup="
        messagePopup = $event;
        showModal = true;
      "
    />
    <router-view
      v-slot="{ Component }"
      @popup="
        messagePopup = $event;
        showModal = true;
      "
    >
      <Icon
        v-if="
          router.currentRoute.value.meta &&
          router.currentRoute.value.meta.comeBack
        "
        class="comeback-button"
        type="arrowLeft"
        :width="3"
        color="red"
        @click="router.back()"
      />
      <transition :name="'page'" appear>
        <component :is="Component" />
      </transition>
    </router-view>
    <AppModal :showModal="showModal" @close="showModal = false" :width="32">
      <template #header
        ><h2 v-html="$t(`app.popup.${messagePopup.title || messagePopup}`)"
      /></template>
      <template #body>{{
        messagePopup.body || $t(`app.popup.${messagePopup}Text`)
      }}</template>
      <template #footer>
        <AppButton
          v-if="messagePopup.button"
          :title="'continue'"
          @click="handleButton"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
        <AppButton
          :title="messagePopup.button ? 'cancel' : 'accept'"
          @click="showModal = false"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
      </template>
    </AppModal>
    <Footer />
  </section>
</template>
<script>
import Header from "./Components/Header.vue";
import Loader from "@/components/common/spinner/spinner";
import Footer from "./Components/Footer.vue";
import AppModal from "@/components/common/modal/modal.vue";
import AppButton from "@/components/common/app-button/button.vue";
import Icon from "@/components/common/app-icon/icon.vue";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";

export default {
  components: { Header, Loader, AppModal, AppButton, Icon, Footer },
  setup() {
    let showModal = ref(false);
    let messagePopup = ref();
    const router = useRouter();

    const handleButton = () => {
      showModal.value = false;
      messagePopup.value.button();
    };

    return {
      showModal,
      messagePopup,
      handleButton,
      router: router,
    };
  },
};
</script>
<style lang="scss" scoped>
@import url("../assets/styles/styles.scss");

.comeback-button {
  margin-left: 2%;
}
</style>
