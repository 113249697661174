<template>
  <section class="main-view">
    <AppSwitch
      @toggle="callback('switch', $event)"
      :isEnabled="!enabledSwitch"
    />
    <section class="button-filter">
        <AppButton
          v-if=" user.role == 'ADMINENTITY'"
          style="flex: 1"
          title="download"
          @click="callback('download')"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
      </section>
    <section v-if="enabledSwitch" class="filter-section">
      <AppInputDate
        class="date-filter"
        title="init.dates"
        :value="{ nbf: filter['nbf'], exp: filter['exp'] }"
        @update:nbf="filter.nbf = $event"
        @update:exp="filter.exp = $event"
      />
      <AppSelect
        class="type-filter"
        placeholder="selectOne"
        title="init.type"
        attr="type"
        :options="types"
        v-model:value="filter['typeId']"
      />
      <AppSelect
        v-if="
          user &&
          user.role !== 'ENTITY' &&
          user.role != 'ADMINENTITY' &&
          user.role != 'MANAGERENTITY'
        "
        class="entity-filter"
        placeholder="selectOne"
        title="init.entity"
        attr="entity"
        :options="entities"
        v-model:value="filter['entityId']"
      />
      <section class="button-filter">
        <AppButton
          style="flex: 1"
          title="filter"
          @click="callback('filter')"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
        <AppButton
          style="flex: 1"
          title="remove"
          @click="callback('remove')"
          :color="{ color: 'secondary', hover: 'secondary' }"
        />
      </section>
    </section>
    <section v-if="!isMobile && enabledSwitch" class="table-init">
      <AppTable
        :tableHeaders="tableHeaders"
        v-model:items="complaints"
        @clickOne="callback('info', $event)"
        v-model:page="page"
        :totalElements="totalElements"
        :chunksize="chunksize"
      />
    </section>
    <AppItems
      v-if="isMobile && enabledSwitch"
      v-model:items="complaints"
      v-model:page="page"
    />
    <section v-if="!enabledSwitch" class="charts-section">
      <section v-for="(chart, i) in charts" :key="chart.id">
        <h4 v-html="$t(`form.init.chart.${chart.title}`)" />
        <AppChart :data="chart.data" :index="i" />
        <ul
          class="legend-section"
          v-if="chart && chart.data && chart.data.labels"
        >
          <li v-for="(legend, i) in chart.data.labels" :key="i">
            <p
              :style="{
                padding: '.5vh .5vw',
                backgroundColor: chart.data.datasets[0].backgroundColor[i],
              }"
            />
            {{ legend }}
          </li>
        </ul>
      </section>
    </section>
  </section>
</template>

<script>
import AppTable from "../../components/common/app-paginated-table/app-table.vue";
import AppSelect from "../../components/common/app-select/select.vue";
import AppButton from "../../components/common/app-button/button.vue";
import AppItems from "../../components/common/app-items/items.vue";
import AppSwitch from "../../components/common/app-switch-button/app-button-switch.vue";
import AppChart from "../../components/common/app-chart/chart.vue";
import AppInputDate from "../../components/common/app-input-date/app-input-date.vue";
import { computed, ref, watch, onBeforeMount } from "vue";
import { useStore } from "vuex";
import {
  getAllComplaints,
  getKPIs,
  getComplaintById,
  downloadHistory
} from "../../services/complaint";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "Init",
  components: {
    AppInputDate,
    AppSelect,
    AppButton,
    AppSwitch,
    AppTable,
    AppItems,
    AppChart,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const complaints = computed(() => store.getters[`Setting/getComplaints`]);
    const totalElements = computed(
      () => store.getters[`Setting/getTotalElements`]
    );
    const entities = computed(() => store.getters[`Setting/getEntities`]);
    const chunksize = 10;
    let filter = ref({});
    let filterString = ref("");
    let enabledSwitch = ref(true);
    const page = ref(0);
    const types = computed(() => store.getters[`Setting/typeState`]);
    let dataCollection = computed(() => store.getters[`Setting/chartState`]);
    let charts = ref([]);
    const { tm } = useI18n();

    onBeforeMount(async () => {
      getComplaints();
    });

    watch(
      () => page.value,
      () => {
        getComplaints();
      }
    );

    const getComplaints = async () => {
      await getAllComplaints({
        filter: filterString.value,
        offset: page.value,
        limit: chunksize,
      });
    };

    function callback(action, payload) {
      switch (action) {
        case "info":
          getComplaintById({ complaintId: payload.complaintId });
          /*TODO redirection weird*/
          if (payload.statusId === 1) {
            router.push(`/admin/complaint/${payload.complaintId}/assignation`);
          } else {
            router.push(
              `/admin/complaint/${payload.complaintId}/information-complaint`
            );
          }
          break;
        case "filter":
          let aux = Object.keys(filter.value).map((el) => {
            return !!filter.value[el] ? `&${el}=${filter.value[el]}` : "";
          });
          filterString.value = aux.join("");
          getComplaints();
          if (filterString.value !== "") page.value = 0;
          break;
        case "remove":
          filter.value = {};
          filterString.value = "";
          getComplaints();
          break;
        case "switch":
          enabledSwitch.value = payload;
          if (!enabledSwitch.value) getDataChart();
          break;
        case "download":
          downloadHistory(process.env.VUE_APP_ENTITY_ID); 
          break;
      }
    }

    async function getDataChart() {
      var response = await getKPIs();
      response.type.label = response.type.label.map((label, index) => {
        return tm(`app.complaintTypes.${index + 1}.type`);
      });
      response.duration.label = response.duration.label.map((label, index) => {
        return tm(`app.durationTypes.${index + 1}`);
      });
      response.status.label = response.status.label.map((label, index) => {
        const status = {
          1: "Creada",
          2: "Asignada",
          3: "Cerrada",
          4: "Reabierta",
          5: "Finalizada",
          6: "Descartada",
        };
        const indexStatus = Object.keys(status).find(
          (key) => status[key] === label
        );
        return tm(`app.status.${indexStatus}`);
      });
      dataCollection.value.forEach((element, i) => {
        if (response[element.title]) {
          store.dispatch(`Setting/chartDataAction`, {
            index: i,
            label: response[element.title].label,
            data: response[element.title].data,
          });
        }
      });
      charts.value = dataCollection.value;
    }

    return {
      types,
      callback,
      page,
      filter,
      chunksize,
      totalElements,
      complaints,
      entities,
      user: JSON.parse(sessionStorage.getItem("userConnected")),
      isMobile: computed(() => store.getters[`Setting/mobileState`]),
      enabledSwitch,
      charts,
      tableHeaders: [
        { key: "complaintId", class: 2 },
        { key: "createdAt", class: 1.5 },
        { key: "lastUpdatedAt", class: 1.5 },
        { key: "type", class: 2.0 },
        { key: "statusDescription", class: 0.5 },
        { key: "entityDescription", class: 1 },
        { key: "entitySiteDescription", class: 0.75 },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-section {
  display: flex;
  flex-flow: row wrap;
  align-self: flex-end;
  width: 71vw;
  justify-content: flex-end;
  align-items: baseline;
  row-gap: 3vh;
  column-gap: 15px;

  .date-filter {
    flex-grow: 1;
    flex-basis: 12vw;
    margin-right: 2vw;
  }

  .type-filter {
    flex-grow: 3;
    flex-basis: 20vw;
    margin-right: 5vw;
  }
  .entity-filter {
    flex-grow: 3;
    flex-basis: 8vw;
    margin-right: 2vw;
  }

  .button-filter {
    flex-grow: 1;
    flex-basis: 7vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    row-gap: 1vh;
  }

  @media all and (max-width: 500px) {
    width: 100%;
    padding: 0 5vw;
    gap: 10px;

    .date-filter,
    .type-filter,
    .button-filter {
      flex-grow: 1 !important;
      flex-basis: 100%;
      flex-direction: row;
      column-gap: 10px;
      margin: 0;
    }
    .button-filter {
      column-gap: 10px;
      .button-app {
        flex: 1 !important;
      }
    }
  }
}

.table-init {
  align-self: center !important;
  width: 90%;
}

.charts-section {
  display: grid;
  width: 80%;

  @media all and (max-width: 500px) {
    width: 90%;
  }

  margin: 0 5vw 0 5vw;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  column-gap: 8vh;
  align-items: center;
  justify-content: center;
  text-align: center;

  .legend-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1.2vh;
    list-style: none;
    margin-top: 2vh;
    width: 100%;
    padding: 1vh 0.5vw;
    border: 1px dashed var(--mq-gray-light);
    justify-content: space-between;
    min-height: 10vh;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    p {
      width: 15px;
    }
  }
}
</style>
