<template>
  <section
    class="icon-container"
    v-html="icon"
    :style="{
      '--color-icon': `var(--mq-${color})`,
      width: width ? `${width}em` : '15px',
      height: width ? `${width}em !important` : '15px',
      padding: `0 ${padding}vw 0 ${padding}vw`,
      '--hover': `var(--mq-${hover})`,
    }"
    :title="info"
  />
</template>
<script>
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  name: "Icon",
  props: ["type", "color", "info", "width", "padding", "hover"],
  setup(props) {
    let icon = ref("");
    const store = useStore();
    const icons = computed(() => store.getters["Setting/iconState"]);

    onMounted(() => (icon.value = icons.value[props.type]));
    return {
      icon,
    };
  },
};
</script>

<style lang="scss">
.icon-container {
  display: flex;
  transition: all 0.25s ease-out;
  flex-direction: column;
  justify-content: center;
  height: fit-content !important;
  svg {
    fill: var(--color-icon);
    &:hover {
      path {
        fill: var(--hover);
      }
    }
  }
}
</style>
