<template>
  <section class="container-languages">
    <span>{{ selectedLanguage.description }} </span>
    <img
      :src="selectedLanguage.flag"
      width="20"
      height="15"
      style="margin-left: 5px"
    />
    <ul class="languages">
      <li
        v-for="language in languages"
        :key="language.key"
        @click="setLocale(language.key)"
      >
        <p v-html="language.description" />
        <img :src="language.flag" width="20" height="15" />
      </li>
    </ul>
  </section>
</template>
<script>
import { ref, computed } from "vue";
import Icon from "../common/app-icon/icon.vue";
import { useStore } from "vuex";
import { i18n } from "../../main";

export default {
  name: "Languages",
  components: { Icon },
  setup() {
    const store = useStore();
    const showLanguages = ref(false);
    const languages = computed(() => store.getters["Setting/getLanguages"]);
    const { t } = i18n.global;

    let selectedLanguage = ref(
      languages.value.find((language) =>
        i18n.global.locale.value !== "undefined"
          ? language.key === i18n.global.locale.value
          : language.key === "sp"
      )
    );
    let changeLanguageSp = {
        index: 0,
        description: t(`language.sp`)
    }
    let changeLanguageCat = {
        index: 1,
        description: t(`language.cat`)
    }
    let changeLanguageEn = {
        index: 2,
        description: t(`language.en`)
    }
      store.dispatch("Setting/setCountries", changeLanguageSp);
      store.dispatch("Setting/setCountries", changeLanguageCat);
      store.dispatch("Setting/setCountries", changeLanguageEn);

    const setLocale = (locale) => {
      if (i18n.global.locale.value !== locale) {
        selectedLanguage.value = languages.value.find(
          (language) => language.key === locale
        );
        localStorage.Lang = locale;
        i18n.global.locale.value = locale;
        window.location.reload();

      }
    };
    
    return {
      setLocale,
      selectedLanguage,
      showLanguages,
      languages,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-languages {
  position: relative;

  .languages {
    color: var(--mq-header);
    background-color: var(--mq-white);
    z-index: 1;
    right: 0;
    padding: 0;
    max-height: 40vh;
    overflow: auto;
    display: none;
    position: absolute;
    list-style: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    li {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      border-bottom: 1px solid var(--mq-gray-light);
      padding: 1em 0.5em;
      font-size: 0.8em;
      transition: all 0.5s ease;

      &:hover {
        background-color: var(--mq-gray);
      }
    }
  }
}
.container-languages:hover .languages {
  display: block;
}
</style>
