<template>
  <div style="margin: 0 auto 2vh auto">
    <section class="pagination-menu">
      <Icon style="transform: rotate(270deg)" type="arrow" @click="page==0?null:$emit('update:page',currentPage - 1)" hover="secondary" :padding=".5" :width="2"/>
      <button v-for="tab in shownTabs" :key="tab.id" 
        :class="[isANumber(tab.name),isSelected(tab)]" 
        @click="isANumber(tab.name)=='item-pagination'?$emit('update:page', tab.name - 1) && isActivePage(tab):null"
        v-html="tab.name"
      />
      <Icon style="transform: rotate(90deg)" type="arrow" @click="page==tabs-1?null:$emit('update:page',currentPage + 1)" hover="secondary" :padding=".5" :width="2"/>
    </section>
  </div>
</template>
<script>
import { ref, watch, onMounted } from 'vue'
import Icon from '../app-icon/icon.vue'
export default {
  name: 'Pagination',
  components: {Icon},
	props: { 
    page: { default: 0 },
    totalElements: { default: 10},
		chunksize: { default: 6, type: Number },
    maxTabsShown: {  default: 5, type: Number }
  },
  setup(props) {
    const tabs = ref(2)
    const currentPage = ref(1)
    const shownTabs = ref([])

    onMounted(() => chunkify(props.page))

    watch(() => props.totalElements, (val, oldVal) => { 
      chunkify(props.page) 
    })
    watch(() => props.page, (val, oldVal) => { 
      chunkify(val) 
    })

    function chunkify(page) {
      if(tabs.value == 0) tabs.value = 1;
      if(page >= 0 && page <= tabs.value) {
        currentPage.value = page;
        tabs.value=Math.ceil(props.totalElements/props.chunksize);
        let lastTab = {name: tabs.value, selected:false};
        let firstTab = {name: 1, selected: false};

          // if there are more tabs than it's allowed
        if(tabs.value > props.maxTabsShown) {
          shownTabs.value=[];
          let indexTabs = 0;

          for(let i=page + 1; i<page + 1 + props.maxTabsShown - 2; i++) {
            let obj ={};
            // Verify the position of item selected
            // If 3 or more, show two items at right and left
            if(page > 2) obj = {name: i-2, selected:false};
            // If 2 items, show only the first at left and three items at right
            else if(page == 2) obj = {name: i-1, selected:false};
            // If one item, show only four items at right
            else obj = {name: i, selected:false};

            shownTabs.value.push(Object.assign({}, obj));
            if(shownTabs.value[indexTabs].name > tabs.value-1) {
              shownTabs.value.pop();
              break;
            }
            indexTabs++;
          }
            // Before last item, add '...'
          if(shownTabs.value[indexTabs-1].name != tabs.value-1) {
            shownTabs.value.push({name: '...', selected: false});
          } 
          if(shownTabs.value[2] && shownTabs.value[2].name > 3 || shownTabs.value[indexTabs-1] && shownTabs.value[indexTabs-1].name == tabs.value-1) {
            shownTabs.value.unshift({name: '...', selected: false});
            shownTabs.value.unshift({name: 1, selected: false});
          }
          shownTabs.value.push(lastTab);
        } else { // if there are less than it's allowed, it should paint the tabs ONCE
          if(currentPage.value + 1 == firstTab.name) {
            shownTabs.value = [];
            for(let i=page; i<page+tabs.value; i++) {
              let obj = {name: i + 1, selected:false};
              shownTabs.value.push(Object.assign({}, obj));
            }
          }
        }
        shownTabs.value.filter(item=>{
          if(item.name == page + 1) item.selected = true;
          else item.selected = false;
        });
      }
    }

    function isANumber(number) {
      if(Number.isFinite(number)) {
        return 'item-pagination'
      } else {
        return 'item-ellipsis'
      }
    }

    function isActivePage(page) {
      page.selected = true;
    }
    
    function isSelected(page) {
      if(page.selected == true) {
        return 'item-selection'
      } 
    }

    return {
      currentPage,
      shownTabs,
      tabs,
      isANumber,
      isSelected,
      isActivePage
    }
  }
}	
	
</script>
<style lang="scss">
    @import 'pagination.scss';
</style>