<template>
  <Header />
  <router-view
    v-slot="{ Component }"
    @popup="
      messagePopup = $event;
      showModal = true;
    "
  >
    <Icon
      v-if="
        router.currentRoute.value.meta &&
        router.currentRoute.value.meta.comeBack
      "
      class="comeback-button"
      type="arrowLeft"
      :width="3"
      color="red"
      @click="router.back()"
    />
    <component :is="Component" />
  </router-view>
  <AppModal :showModal="showModal" @close="showModal = false" :width="32">
    <template #header>
      <h2 v-html="$t(`app.popup.${messagePopup}`)" />
    </template>
    <template #body>{{ $t(`app.popup.${messagePopup}Text`) }}</template>
  </AppModal>
  <!--<Footer />-->
</template>
<script>
import Header from "./Components/Header.vue";
import Footer from "./Components/Footer.vue";
import AppModal from "@/components/common/modal/modal.vue";
import AppButton from "@/components/common/app-button/button.vue";
import { ref } from "@vue/reactivity";
import Icon from "@/components/common/app-icon/icon.vue";
import { useRouter } from "vue-router";

export default {
  name: "BlankLayout",
  components: { Header, Icon, AppModal, AppButton, Footer },
  setup() {
    let showModal = ref(false);
    let messagePopup = ref();
    const router = useRouter();

    return {
      showModal,
      messagePopup,
      router: router,
    };
  },
};
</script>
<style lang="scss" scoped>
@import url("../assets/styles/styles.scss");
.comeback-button {
  margin-left: 5%;
}
</style>
