import axios from "axios";
var jwt = require("jsonwebtoken");

const _decodeToken = (arrayWebToken) => {
  let webToken = jwt.decode(arrayWebToken);
  sessionStorage.setItem(
    "userConnected",
    JSON.stringify({
      role: webToken.realm_access.roles.filter(
        (el) =>
          el === "ADMIN" ||
          el === "MANAGER" ||
          el === "ENTITY" ||
          el === "ADMINENTITY" ||
          el === "MANAGERENTITY"
      )[0],
      user: webToken.email,
    })
  );
};

export const login = async ({ userConnected }) => {
  var response = {};
  if (!!userConnected) {
    try {
      response = await axios.post(`${process.env.VUE_APP_BASE_URL}/login`, {
        username: userConnected.user,
        password: userConnected.password,
      });
      sessionStorage.setItem(
        "authToken",
        `${response.data.token_type} ${response.data.access_token}`
      );
      _decodeToken(response.data.access_token);
      return response.status;
    } catch (e) {
      return e.response.status;
    }
  } else {
    return { status: null };
  }
};

export const reset = async ({ user }) => {
  var response = {};
  if (!!user) {
    try {
      response = await axios.post(`${process.env.VUE_APP_BASE_URL}/reset`, {
        username: user.user,
      });
      return response.status;
    } catch (e) {
      return e.response.status;
    }
  } else {
    return 400;
  }
};
