<template>
  <section class="main-view">
    <!--  v-if="user.role === 'ADMIN' || user.role === 'ADMINENTITY'"-->
    <h3
      v-if="user.role === 'ADMIN' || user.role === 'ADMINENTITY'"
      v-html="
        $t('form.complaints.complaints').toUpperCase() +
        ' ' +
        $t('form.complaints.notAssigned').toUpperCase()
      "
    />
    <section
      v-if="user.role === 'ADMIN' || user.role === 'ADMINENTITY'"
      class="filter-section-not-assigned"
    >
      <AppInputDate
        class="date-filter-not-assigned"
        title="init.dates"
        :value="{ nbf: filter['nbf'], exp: filter['exp'] }"
        @update:nbf="filter.nbf = $event"
        @update:exp="filter.exp = $event"
      />
      <AppSelect
        class="type-filter-not-assigned"
        placeholder="selectOne"
        title="init.type"
        attr="type"
        :options="types"
        v-model:value="filter['typeId']"
      />
      <AppSelect
        v-if="user && user.role !== 'ENTITY' && user.role !== 'ADMINENTITY'"
        class="entity-filter-not-assigned"
        placeholder="selectOne"
        title="init.entity"
        attr="entity"
        :options="entities"
        v-model:value="filter['entityId']"
      />
      <section class="button-filter">
        <AppButton
          title="filter"
          @click="callback('filter1')"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
        <AppButton
          title="remove"
          @click="callback('remove1')"
          :color="{ color: 'secondary', hover: 'secondary' }"
        />
      </section>
    </section>
    <section
      v-if="user.role === 'ADMIN' || user.role === 'ADMINENTITY'"
      :style="['table-complaint', { width: isMobile ? '100%' : '95%' }]"
    >
      <AppTable
        v-if="!isMobile"
        :tableHeaders="tableHeadersNotAssigned"
        v-model:items="notAssignedComplaints"
        @clickOne="callback('assignation', $event)"
        v-model:page="pageNotAssigned"
        :chunksize="chunksize"
        :totalElements="totalElementsNotAssigned"
      />
      <AppItems
        v-else
        v-model:items="notAssignedComplaints"
        v-model:page="pageNotAssigned"
        @clickOne="callback('assignation', $event)"
      />
    </section>
    <h3
      v-html="
        $t('form.complaints.complaints').toUpperCase() +
        ' ' +
        $t('form.complaints.assigned').toUpperCase()
      "
    />
    <section class="filter-section-assigned">
      <AppInputDate
        class="date-filter-assigned"
        title="init.dates"
        :value="{ nbf: filter2['nbf'], exp: filter2['exp'] }"
        @update:nbf="filter2.nbf = $event"
        @update:exp="filter2.exp = $event"
      />
      <AppSelect
        class="type-filter-assigned"
        placeholder="selectOne"
        title="init.type"
        attr="type"
        :options="types"
        v-model:value="filter2['typeId']"
      />
      <AppSelect
        v-if="
          user &&
          user.role !== 'ENTITY' &&
          user.role !== 'ADMINENTITY' &&
          user.role !== 'MANAGERENTITY'
        "
        class="entity-filter-assigned"
        placeholder="selectOne"
        title="init.entity"
        attr="entity"
        :options="entities"
        v-model:value="filter2['entityId']"
      />
      <section class="button-filter">
        <AppButton
          title="filter"
          @click="callback('filter2')"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
        <AppButton
          title="remove"
          @click="callback('remove2')"
          :color="{ color: 'secondary', hover: 'secondary' }"
        />
      </section>
    </section>
    <section :style="['table-complaint', { width: isMobile ? '100%' : '95%' }]">
      <AppTable
        v-if="!isMobile"
        :tableHeaders="tableHeadersAssigned"
        v-model:items="assignedComplaints"
        @clickOne="callback('resolution', $event)"
        v-model:page="pageAssigned"
        :chunksize="chunksize"
        :totalElements="totalElementsAssigned"
      />
      <AppItems
        v-else
        v-model:page="pageAssigned"
        v-model:items="assignedComplaints"
        @clickOne="callback('resolution', $event)"
      />
    </section>
  </section>
</template>

<script>
import AppTable from "@/components/common/app-paginated-table/app-table.vue";
import AppItems from "@/components/common/app-items/items.vue";
import AppButton from "@/components/common/app-button/button.vue";
import AppInputDate from "@/components/common/app-input-date/app-input-date.vue";
import AppSelect from "@/components/common/app-select/select.vue";
import { onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { getAllComplaints } from "@/services/complaint";
import { useRouter } from "vue-router";

export default {
  name: "Init",
  components: { AppTable, AppItems, AppSelect, AppInputDate, AppButton },
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = ref(JSON.parse(sessionStorage.getItem("userConnected")));
    const assignedComplaints = computed(
      () => store.getters[`Setting/getAssignedComplaints`]
    );
    const notAssignedComplaints = computed(
      () => store.getters[`Setting/getNotAssignedComplaints`]
    );
    const totalElementsAssigned = computed(
      () => store.getters[`Setting/getTotalElementsAssigned`]
    );
    const totalElementsNotAssigned = computed(
      () => store.getters[`Setting/getTotalElementsNotAssigned`]
    );
    const types = computed(() => store.getters[`Setting/typeState`]);
    const entities = computed(() => store.getters[`Setting/getEntities`]);
    let filter = ref({});
    let filterString = ref("");
    let filter2 = ref({});
    let filter2String = ref("");
    const pageAssigned = ref(0);
    const pageNotAssigned = ref(0);
    const chunksize = ref(10);

    onMounted(() => {
      getComplaintNotAssigned();
      getComplaintAssigned();
    });

    watch(
      () => pageNotAssigned.value,
      () => {
        getComplaintNotAssigned();
      }
    );
    watch(
      () => pageAssigned.value,
      () => {
        getComplaintAssigned();
      }
    );

    const getComplaintNotAssigned = async () => {
      await getAllComplaints({
        filter: filterString.value,
        status: ["1"],
        offset: pageNotAssigned.value,
        limit: chunksize.value,
      });
    };
    const getComplaintAssigned = async () => {
      await getAllComplaints({
        filter: filter2String.value,
        status: ["2", "4"],
        offset: pageAssigned.value,
        limit: chunksize.value,
      });
    };

    async function callback(action, complaint) {
      switch (action) {
        case "assignation":
        case "resolution":
          store.dispatch(`Setting/setComplaint`, complaint);
          router.push(`/admin/complaint/${complaint.complaintId}/${action}`);
          break;
        case "filter1":
          let aux = Object.keys(filter.value).map((el) => {
            return !!filter.value[el] ? `&${el}=${filter.value[el]}` : "";
          });
          filterString.value = aux.join("");
          getComplaintNotAssigned();
          if (filterString.value !== "") pageNotAssigned.value = 0;
          break;
        case "filter2":
          let aux2 = Object.keys(filter2.value).map((el) => {
            return !!filter2.value[el] ? `&${el}=${filter2.value[el]}` : "";
          });
          filter2String.value = aux2.join("");
          getComplaintAssigned();
          if (filter2String.value !== "") pageAssigned.value = 0;
          break;
        case "remove1":
          filter.value = {};
          filterString.value = "";
          getComplaintNotAssigned();
          break;
        case "remove2":
          filter2.value = {};
          filter2String.value = "";
          getComplaintAssigned();
          break;
      }
    }

    return {
      types,
      assignedComplaints,
      totalElementsAssigned,
      notAssignedComplaints,
      totalElementsNotAssigned,
      callback,
      pageAssigned,
      pageNotAssigned,
      filter,
      filter2,
      entities,
      chunksize,
      isMobile: computed(() => store.getters[`Setting/mobileState`]),
      user,
      tableHeadersNotAssigned: [
        { key: "complaintId", class: 0.8 },
        { key: "createdAt", class: 0.8 },
        { key: "type", class: 1.5 },
        { key: "statusDescription", class: 0.4 },
        { key: "entityDescription", class: 0.5 },
        { key: "entitySiteDescription", class: 0.3 },
      ],
      tableHeadersAssigned: [
        { key: "complaintId", class:  0.7 },
        { key: "createdAt", class: 0.5 },
        { key: "lastUpdatedAt", class: 0.5},
        { key: "type", class: 0.8 },
        { key: "manager", class: 0.5 },
        { key: "statusDescription", class: 0.2 },
        { key: "entityDescription", class: 0.45 },
        { key: "entitySiteDescription", class: 0.3 },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./complaint.scss";
</style>
