<template>
  <div id="footer-menu">
  </div>
</template>
<script>

export default {
  name: "Footer",
};
</script>
<style scoped lang="scss">
#footer-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 2vh 0;
  transition: all 0.25s ease;
}
</style>
