import Service from "./services";
import constantURL from "../config/constant";
import store from "../store/index.js";
import { i18n } from "../main";

var services = new Service();
var roles = store.getters[`Setting/roleState`];
export const createUpdateUser = async ({ request, id }) => {
  let response;
  let byId = !!id ? `/${id}` : "";

  try {
    response = await services.post(constantURL.user + byId, request);
  } catch (error) {
    return error.response.status;
  }
  return response.status;
};

export const deleteUser = async (id) => {
  let response;
  let byId = !!id ? `/${id}` : "";

  try {
    response = await services.post(constantURL.user + byId + '/delete');
  } catch (error) {
    console.log(error);
    return error.response.status;
  }
  return response.status;
}

export const getManagers = async ({ entityId }, role) => {
  let response;

  try {
    if (role === 1) {
      response = await services.get(
        `${constantURL.user}?entityId=${entityId}&role[]=1&role[]=2&role[]=4&role[]=5`
      );
    } else if (role === 4) {
      response = await services.get(
        `${constantURL.user}?entityId=${entityId}&role[]=4&role[]=5`
      );
    }
  } catch (error) {
    return error.response;
  }
  response.data.content.forEach((el) => {
    if (el.role !== 4) {
      el["username"] = `${el.name} ${el.firstFamilyName} - ${el.email}`;
    } else {
      el["username"] = `${el.entities[0].entity} - ${el.email}`;
    }
  });
  return response.data.content;
};

export const getAllNotifications = async ({ email, offset, limit }) => {
  const { t } = i18n.global;

  try {
    const { data } = await services.get(
      `${constantURL.user}/${email}/notification?read=2&offset=${offset}&limit=${limit}`
    );
    const { content: notificationsAPIResponse } = data;
    if (notificationsAPIResponse && notificationsAPIResponse.length > 0) {
      let notifications = notificationsAPIResponse.map((notification) => {
        if (!notification.readAt)
          return {
            ...notification,
            ...{
              notificationReason: t(
                `app.notificationStatus.${notification.notificationReasonId}`
              ),
            },
          };
      });
      return notifications;
    }
  } catch (error) {
    return error.response.status;
  }
};

export const getUser = async ({ id }) => {
  let response;

  try {
    response = await services.get(`${constantURL.user}/${id}`);
  } catch (error) {
    return error.response;
  }

  return response.data;
};

export const getUserByEntityIdAndRole = async ({ id }) => {
  let response;

  try {
    response = await services.get(`anonymous/${constantURL.user}/entity/${id}`);
    sessionStorage.setItem("entityUser", JSON.stringify(response.data));
  } catch (error) {
    return error.response;
  }

  return response.data;
};

export const getAllUsers = async ({ filter, offset, limit }) => {
  let response;
  let byField = filter.length > 0 ? `${filter}` : "";

  try {
    response = await services.get(
      `${constantURL.user}?offset=${offset}&limit=${limit}` + byField
    );
  } catch (error) {
    return error.response;
  }
  if (response.data.content.length > 0) {
    response.data.content.forEach((el) => {
      if (el.role === roles.length) {
        el["entity"] = el.entities[0].entity;
        el["sites"] = el.entities[0].sites;
      }
      el["fullname"] = el.name ? `${el.name} ${el.firstFamilyName}` : "";
      if (el.entities && el.entities.length > 0)
        el["clients"] = el.entities.map((item) => {
          return `${item.entity}`;
        });
    });
  }
  await store.dispatch("Setting/setUsers", response.data);
};

export const readNotification = async ({ notificationId, userId }) => {
  let response;

  try {
    response = await services.post(
      `${constantURL.notification}/${notificationId}`,
      { userId: userId }
    );
  } catch (error) {
    return error.response;
  }
  return response.data;
};
