<template>
  <section class="multi-input">
    <h5 v-if="title" v-html="$t(`form.${title}`)"/>
    <section>
      <input
        :class="{'input-error':error}"
        :disabled="disabled"
        @keyup.enter="addOption(input)" 
        :placeholder="placeholder?$t(`form.${placeholder}`):''"
        v-model="input"
        :type="type  || 'text'"
        @change="$emit('update:value', options)">
      <section v-if="options" class="options-section">
        <p v-for="(option,i) in options" :key="option.key">
          {{option.description}}
          <Icon type="cross" :width="0.75" style="cursor: pointer" @click="options.splice(i, 1)"/>
        </p>
      </section>
    </section>
    <section v-if="error" style="color:var(--mq-secondary); display:inline-flex; margin:.5vh .25vw">
      <Icon type="alert" :width="1" :color="'secondary'"/>
    {{$t('form.verify')}}
    </section>
  </section>
</template>
<script>
import { onMounted, ref, watch } from 'vue'
import Icon from '../app-icon/icon.vue'

export default {
  name: 'AppInput',
  props: ['title','placeholder','value','disabled', 'type', 'error'],
  components: { Icon },
  setup(props) {
    let options = ref([])
    let input = ref(null)

    onMounted(()=> {if(!!props.value) options.value = props.value })

    function addOption(val){
      options.value.push({description: val})
      input.value = ''
    }

    return { 
      options,
      addOption,
      input
    }
  }
}
</script>

<style lang="scss" scoped>
    @import './multi-input.scss';
</style>
