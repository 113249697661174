<template>
  <div class="v-ring" :style="spinnerStyle">
    <div class="v-ring v-ring2"/>
    <div class="v-ring v-ring3"/>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
export default {  
  name: 'RingLoader',
  props: ['color','size'],
  setup(props) {
    let spinnerStyle = computed(() => {
      return{
        '--size-spinner': props.size + 'vw',
        '--color-spinner': `var(--mq-${props.color}`
      }
    })
    return {spinnerStyle}
  }
}
</script>

<style lang="scss">

.v-ring {  width: var(--size-spinner); height: var(--size-spinner); }

.rings-common {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    perspective: 800px;  
    position: absolute;          
    border: calc(var(--size-spinner)/10) solid var(--color-spinner);
    border-radius: 100%;
    opacity:0.6;
    width: var(--size-spinner);
    height: var(--size-spinner) ;
}

.v-ring2 { 
  @extend .rings-common; 
  -webkit-animation: v-ringRightRotate 2s 0s infinite linear; 
  animation: v-ringRightRotate 2s 0s infinite linear;
}

.v-ring3 {
  @extend .rings-common; 
  -webkit-animation: v-ringLeftRotate 2s 0s infinite linear;
  animation: v-ringLeftRotate 2s 0s infinite linear;
}

@-webkit-keyframes v-ringRightRotate
{
    0%
    {
        -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
                transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    100%
    { 
        -webkit-transform: rotateX(180deg) rotateY(360deg) rotateZ(360deg);
                transform: rotateX(180deg) rotateY(360deg) rotateZ(360deg);
    
    }
}

@keyframes v-ringRightRotate
{
    0%
    {
        -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
                transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    100%
    { 
        -webkit-transform: rotateX(180deg) rotateY(360deg) rotateZ(360deg);
                transform: rotateX(180deg) rotateY(360deg) rotateZ(360deg);
    
    }
}

@-webkit-keyframes v-ringLeftRotate
{
    0%
    {
        -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
                transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    100%
    { 
        -webkit-transform: rotateX(360deg) rotateY(180deg) rotateZ(360deg);
                transform: rotateX(360deg) rotateY(180deg) rotateZ(360deg);
    
    }
}

@keyframes v-ringLeftRotate
{
    0%
    {
        -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
                transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    100%
    { 
        -webkit-transform: rotateX(360deg) rotateY(180deg) rotateZ(360deg);
                transform: rotateX(360deg) rotateY(180deg) rotateZ(360deg);
    
    }
}
</style>