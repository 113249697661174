<template>
  <Loader />
  <Header
    @popup="
      messagePopup = $event;
      showModal = true;
    "
  />
  <section class="image-background">
    <router-view
      @popup="
        messagePopup = $event;
        showModal = true;
      "
    ></router-view>
  </section>
  <!--<Footer />-->
  <AppModal :showModal="showModal" @close="showModal = false" :width="32">
    <template #header
      ><h2 v-html="$t(`login.${messagePopup.title || messagePopup}`)"
    /></template>
    <template #body>{{
      messagePopup.body || $t(`login.${messagePopup}Text`)
    }}</template>
    <template #footer>
      <AppButton
        v-if="messagePopup.button"
        :title="'continue'"
        @click="messagePopup.button"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
      <AppButton
        :title="messagePopup.button ? 'cancel' : 'accept'"
        @click="showModal = false"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
    </template>
  </AppModal>
</template>
<script>
import Loader from "@/components/common/spinner/spinner";
import Header from "./Components/Header.vue";
import Footer from "./Components/Footer.vue";
import AppModal from "@/components/common/modal/modal.vue";
import AppButton from "@/components/common/app-button/button.vue";

import { ref } from "vue";

export default {
  name: "AuthLayout",
  components: { Loader, Header, AppModal, AppButton, Footer },
  setup() {
    let showModal = ref(false);
    let messagePopup = ref({});

    return {
      showModal,
      messagePopup,
    };
  },
};
</script>
<style lang="scss">
@import url("../assets/styles/styles.scss");
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.image-background {
  background-image: url("../assets/images/fondo.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
