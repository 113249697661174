<template>
  <ul class="items-container" @scroll="handleScroll">
    <li
      v-for="(item, i) in rows"
      :key="i"
      @click="
        $emit('clickOne', item);
        item['selected'] = !item['selected'];
      "
      :class="{ selected: item.selected }"
    >
      <div
        style="grid-area: name"
        v-html="
          item.role == 4 || item.role == 3
            ? item.entities[0].entity
            : item.fullname || item.type
        "
      />
      <div style="grid-area: email" v-html="item.email || item.complaintId" />
      <div
        :style="{
          gridArea: 'role',
          textAlign: 'right',
          fontWeight: item.role ? 'bold' : 'normal',
        }"
        v-html="
          item.role ? $t(`table.translate.roles.${item.role}`) : item.createdAt
        "
      />
      <div
        style="grid-area: clients"
        :class="item.statusId ? `status status${item.statusId}` : null"
        v-html="item.clients || item.statusDescription"
      />
    </li>
  </ul>
</template>
<script>
import { watch, ref } from "vue";

export default {
  name: "AppTable",
  props: ["items", "page"],
  setup(props, { emit }) {
    const rows = ref(props.items);
    const handleScroll = (el) => {
      if (
        el.target.offsetHeight + el.target.scrollTop >=
        el.target.scrollHeight
      ) {
        emit("update:page", props.page + 1);
      }
    };

    watch(
      () => props.items,
      (val, oldVal) => {
        rows.value = [...oldVal, ...val];
      }
    );
    return { handleScroll, rows };
  },
};
</script>

<style lang="scss">
@import "./items.scss";
</style>
