<template>
  <section class="select" :style="{ flex: `${width}` }">
    <h5 v-if="title" v-html="$t(`form.${title}`)" />
    <section>
      <div
        :class="['select-customize', { 'select-error': error }]"
        @click="disabled ? null : (expand = !expand)"
      >
        {{ optionSelected || $t(`form.${placeholder}`) }}
        <div class="icon-input">
          <Icon
            :style="{ transform: expand ? 'rotate(0deg)' : 'rotate(180deg)' }"
            type="arrow"
            :width="0.75"
          />
        </div>
      </div>
      <ul v-if="expand">
        <li
          v-for="option in options"
          :key="option.id"
          @click="selectOption(option)"
          v-html="option.name || option[attr] || option"
        />
      </ul>
    </section>
    <section
      v-if="error"
      style="
        color: var(--mq-secondary);
        display: inline-flex;
        margin: 0.5vh 0.25vw;
      "
    >
      <Icon type="alert" :width="1" :color="'secondary'" />
      {{ $t("form.verify") }}
    </section>
  </section>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import Icon from "../app-icon/icon.vue";
export default {
  name: "AppFilter",
  props: [
    "title",
    "placeholder",
    "options",
    "value",
    "attr",
    "disabled",
    "width",
    "error",
  ],
  components: { Icon },
  setup(props, { emit }) {
    let expand = ref(false);
    let optionSelected = ref(null);

    onMounted(() => {
      if (props.options)
        selectOption(props.options.filter((el) => el.id === props.value)[0]);
      //window.onclick = closeSelect;
    });

    const closeSelect = (event) => {
      const select = document.getElementsByClassName("select")[0];
      if (select && select.contains(event.target)) {
        expand.value = true;
      } else expand.value = false;
    };

    watch(
      () => props.value,
      () => {
        if (props.options)
          selectOption(props.options.filter((el) => el.id === props.value)[0]);
      }
    );

    const selectOption = (option) => {
      if (!!option) {
        expand.value = false;
        optionSelected.value =
          option.name || option[props.attr] || option || null;
        emit("update:value", option.id);
      } else {
        optionSelected.value = null;
      }
    };

    return {
      selectOption,
      optionSelected,
      expand,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./select.scss";
</style>
