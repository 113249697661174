<template>
  <section class="file-field">
    <h6 @click="dragAndDrop.openFiles()" v-html="$t(`app.documentMessage2`)" />
    <div v-for="(file, i) in files" :key="file.id" class="file">
      <Icon
        class="delete-file"
        v-if="!disabled && !file.id"
        @click="deleteFile(i)"
        type="cross"
        color="primary"
        :width="1"
      />
      <Icon
        type="document"
        color="primary"
        :width="4"
        @click="download(file)"
      />
      {{ file.name }}
    </div>
    <AppDragAndDrop
      @filesAdded="onFilesAdded"
      ref="dragAndDrop"
      :disabled="disabled"
    />
  </section>
</template>

<script>
import Icon from "../app-icon/icon.vue";
import AppInput from "../app-input/input.vue";
import AppDragAndDrop from "../app-drag-and-drop/drag-and-drop.vue";
import { ref, watch } from "vue";
import { downloadFile } from "../../../services/complaint";
import { useRoute } from "vue-router";

export default {
  name: "AppButton",
  components: { Icon, AppInput, AppDragAndDrop },
  props: ["disabled", "value"],
  setup(props, { emit }) {
    const route = useRoute();
    const complaintId = route.params.id;
    const files = ref(props.value || []);
    let dragAndDrop = ref(null);

    watch(
      () => props.value,
      () => (files.value = props.value)
    );

    const onFilesAdded = (attachments) => {
      files.value = [...files.value, ...attachments];
      emit("update:value", { documents: files.value });
    };
    const deleteFile = (index) => {
      files.value = files.value.filter((_, i) => i != index);
      emit("update:value", { documents: files.value });
    };

    const download = async (file) => {
      await downloadFile({ file, complaintId });
    };

    return {
      onFilesAdded,
      deleteFile,
      download,
      files,
      dragAndDrop,
      payload: {},
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./attachments.scss";
</style>
