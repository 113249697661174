<template>
  <div class="chart-container">
    <canvas :id="`myChart${index}`" />
    <div :id="`tooltip${index}`" class="tooltip-chart" />
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from "@vue/runtime-core";
import Chart from "chart.js/auto";
import { useStore } from "vuex";

export default {
  name: "Doughnut",
  props: ["data", "index"],
  setup(props) {
    const store = useStore();
    let tooltips = props.data.labels;
    let tooltip = ref(null);
    const isMobile = computed(() => store.getters["Setting/mobileState"]);

    let options = ref({
      plugins: {
        legend: {
          display: isMobile ? false : true,
          position: "right",
          maxWidth: 50,
          onHover: function (event, legendItem) {
            tooltip.value.style.opacity = 1;
            tooltip.value.innerHTML = tooltips[legendItem.index];
            tooltip.value.style.padding = ".5vh .5vw";
            tooltip.value.style.left = event.x + "px";
            tooltip.value.style.top = event.y + "px";
          },
          onLeave: function () {
            tooltip.value.style.padding = "0";
            tooltip.value.style.opacity = 0;
            tooltip.value.innerHTML = "";
          },
        },
      },
      animation: { duration: 2500 },
    });
    let myChart = ref(null);

    onMounted(() => renderChart());
    watch(
      () => props.data,
      () => {
        renderChart();
      }
    );

    function renderChart() {
      tooltip.value = document.getElementById("tooltip" + props.index);
      let ctx = document.getElementById(`myChart${props.index}`);
      let myChart = new Chart(ctx, {
        type: "doughnut",
        data: props.data,
        options: options.value,
      });
    }

    return {
      options,
      myChart,
      hover: { animationDuration: 500 },
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          filter: function (legendItem, chartData) {
            return chartData.labels[legendItem.index] != "Vacío";
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  margin: auto;
  .tooltip-chart {
    background-color: var(--mq-gray);
    transition: opacity 1s ease-out !important;
    color: var(--mq-white);
    text-align: center;
    opacity: 0;
    border-radius: 10px;
  }
}
</style>
