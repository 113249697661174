<template>
  <h5 v-if="title" v-html="$t(`form.${title}`)" />
  <textarea
    v-model="textValue"
    :disabled="disabled"
    :class="{ 'input-error': error }"
    :placeholder="placeholder || $t(`app.writeDetail`)"
  />
  <section
    v-if="error"
    style="
      color: var(--mq-secondary);
      display: inline-flex;
      margin: 0.5vh 0.25vw;
    "
  >
    <Icon type="alert" :width="1" :color="'secondary'" />
    {{ $t("form.verify") }}
  </section>
  <!--<section class="counter-textarea">
    <span>{{counter}}</span>
    <span>/ {{maxlength || 200}}</span>
  </section>-->
</template>

<script>
import { watch, ref } from "vue";
import Icon from "../app-icon/icon.vue";

export default {
  name: "Textarea",
  components: { Icon },
  props: ["title", "value", "placeholder", "disabled", "error"],
  setup(props, { emit }) {
    let counter = ref(0);
    let textValue = ref(props.value);

    watch(
      () => props.value,
      () => (textValue.value = props.value)
    );
    watch(
      () => textValue.value,
      async () => {
        counter.value = textValue.value.length;
        emit("update:value", textValue.value);
      }
    );

    const clearTextarea = () => {
      textValue.value = "";
    };

    return {
      textValue,
      counter,
      clearTextarea,
    };
  },
};
</script>

<style lang="scss">
textarea {
  width: 100%;
  border: 1px solid var(--mq-primary);
  min-height: 15vh;
}
.counter-textarea {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
