<p style="margin: 1vh 0" v-html="$t('form.complaints.infoPreAnonymous')" />
<template>
  <section
    class="expand-headline"
    @click="disabled ? null : (expand = !expand)"
  >
    <Icon
      class="icon-expand"
      :style="{ transform: expand ? 'rotate(0deg)' : 'rotate(180deg)' }"
      type="arrow"
      color="header"
    />
    <h5 class="text-expand" v-html="$t(`form.${title}`)" />
  </section>
  <section class="expand-section" v-if="expand">
    <AppInput
      v-model:error="issuerError.errorIssuerName"
      :title="`complaints.issuerName`"
      v-model:value="issuerData['issuerName']"
      @change="updateIssuerData('issuerName', issuerData.issuerName)"
    />
    <AppInput
      v-model:error="issuerError.errorIssuerSurname"
      :title="`complaints.issuerSurname`"
      v-model:value="issuerData['issuerSurname']"
      @change="updateIssuerData('issuerSurname', issuerData.issuerSurname)"
    />
    <AppInput
      v-model:error="issuerError.errorIssuerTelephone"
      :title="`complaints.issuerTelephone`"
      v-model:value="issuerData['issuerTelephone']"
      @change="updateIssuerData('issuerTelephone', issuerData.issuerTelephone)"
    />
    <AppInput
      v-model:error="issuerError.errorIssuerEmail"
      :title="`complaints.issuerEmail`"
      v-model:value="issuerData['issuerEmail']"
      @change="updateIssuerData('issuerEmail', issuerData.issuerEmail)"
    />
  </section>
</template>

<script>
import { ref, watch } from "vue";
import Icon from "../app-icon/icon.vue";
import AppInput from "../app-input/input.vue";
import { useStore } from "vuex";

export default {
  name: "AppExpand",
  props: ["title", "value"],
  components: { Icon, AppInput },
  setup(props, { emit }) {
    const store = useStore();
    const issuerData = ref({
      issuerEmail: props.value.issuerEmail,
      issuerTelephone: props.value.issuerTelephone,
      issuerName: props.value.issuerName,
      issuerSurname: props.value.issuerSurname,
    });
    const issuerError = ref({
      errorIssuerEmail: undefined,
      errorIssuerTelephone: undefined,
      errorIssuerName: undefined,
      errorIssuerSurname: undefined,
    });
    const expand = ref(
      props.value.issuerEmail ||
        props.value.issuerTelephone ||
        props.value.issuerName ||
        props.value.issuerSurname
    );

    const checkIssuerData = () => {
      issuerError.value = {
        errorIssuerEmail: (!issuerData.value.issuerEmail || !(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            issuerData.value.issuerEmail
          )
        )),
        errorIssuerTelephone: (!issuerData.value.issuerTelephone || !(/^\d{9}$/.test(issuerData.value.issuerTelephone))),
        errorIssuerName: !issuerData.value.issuerName,
        errorIssuerSurname: !issuerData.value.issuerSurname,
      };
      return (
        issuerError.value.errorIssuerEmail ||
        issuerError.value.errorIssuerTelephone ||
        issuerError.value.errorIssuerName ||
        issuerError.value.errorIssuerSurname
      );
    };

    const updateIssuerData = (attr, data) => {
      issuerData.value[attr] = data;
      emit("update:value", issuerData.value);
    };

    return {
      issuerData,
      updateIssuerData,
      checkIssuerData,
      expand,
      issuerError,
      isMobile: store.getters["Setting/mobileState"],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/styles.scss";

.expand-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 2vh;
  margin-top: 2vh;
  justify-content: space-between;
  .input {
    width: 30vw !important;
    @media all and (max-width: 500px) {
      margin: 0 1vw 0 1vw;
      width: 100% !important;
    }
  }
}
.expand-headline {
  display: flex;
  flex-direction: row;
  column-gap: 5px;

  .text-expand {
    flex: 1;
  }
}
</style>
