<template>
  <section class="sum-code-view">
    <h1 v-html="$t('form.sum-complaint.success')" />
    <h3 v-html="$t(`form.sum-complaint.warning`)" />
    <h3 v-html="$t(`form.sum-complaint.remember`)" />
    <h4 v-html="$t(`form.sum-complaint.wait`)" />
    <section class="card-code-sum">
      <div class="code-resume" @click="callback('download')">
        <Icon type="keys" :width="2" color="primary" />
        {{ complaint.complaintId }}
      </div>
      <ul>
        {{
          $t(`form.sum-complaint.list-info.header`)
        }}
        <li
          v-for="i in 3"
          :key="i"
          v-html="$t(`form.sum-complaint.list-info.item${i}`)"
        />
      </ul>
      <p v-html="$t(`form.sum-complaint.info`)" />
      <AppButton
        title="goBack"
        @click="callback('goTo', '/')"
        :color="{ color: 'primary', hover: 'secondary' }"
      />
    </section>
  </section>
</template>

<script>
import { downloadReturn } from "@/services/general";
import { useRouter } from "vue-router";
import Icon from "@/components/common/app-icon/icon.vue";
import AppButton from "@/components/common/app-button/button.vue";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "SumCode",
  components: { Icon, AppButton },
  setup() {
    const router = useRouter();
    const store = useStore();
    const complaint = computed(() => store.getters[`Setting/getComplaint`]);

    onMounted(() => callback("download"));

    function callback(action, payload) {
      switch (action) {
        case "download":
          downloadReturn({
            content: {
              message: "Aquí tiene su denuncia",
              trackNumber: complaint.value.complaintId,
            },
          });
          break;
        case "goTo":
          store.dispatch(`Setting/setComplaint`, undefined);
          router.push(payload);
          break;
      }
    }
    return {
      complaint,
      callback,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./home.scss";
</style>
