<template>
  <section class="dates">
    <h5 v-if="title" v-html="$t(`form.${title}`)"/>
    <div class="input">
      De<section>
        <input type="date"
          v-model="nbf"
          @change="$emit('update:nbf', moment($event.target.value).format('YYYY-MM-DD HH:mm:ss'))"
          :disabled="disabled"
          @keyup.enter="$emit('keyup')">
      </section>a
      <section>
        <input type="date"
          v-model="exp"
          @change="$emit('update:exp', moment($event.target.value).format('YYYY-MM-DD HH:mm:ss').replace('00:00', '23:59'))"
          :disabled="disabled"
          @keyup.enter="$emit('keyup')">
      </section>
    </div>
  </section>
</template>
<script>
import { ref, watch } from 'vue'
import moment from 'moment'

export default {
  name: 'AppInputDate',
  props: ['title','value', 'disabled'],
  setup(props){
//    const moment = require('moment')
    const nbf = ref(null)
    const exp = ref(null)

    watch(()=>props.value, ()=>{
      if(props.value['nbf']===undefined) nbf.value = null
      if(props.value['exp']===undefined) exp.value = null
    })

    return {
      nbf,
      exp,
      moment:moment
    }
  }
}
</script>

<style lang="scss" scoped>
    @import './app-input-date.scss';
</style>
