<template>
  <section class="header_table-component">
    <div
      v-for="header in tableHeaders"
      :key="header.key"
      :style="{ flex: header.class }"
    >
      {{ $t(`table.${header.key}`) }}
      <span v-if="header.required">*</span>
    </div>
  </section>
  <ul class="table-component">
    <li
      v-for="(item, i) in items"
      :key="i"
      @click="
        $emit('clickOne', item);
        selectOne(item, i);
      "
      class="line-table"
    >
      <div
        v-for="header in tableHeaders"
        :key="header.id"
        :style="{ flex: header.class }"
      >
        <section
          :title="item.description || item[header.key]"
          v-html="getRowValue(header, item)"
        />
      </div>
    </li>
  </ul>
  <AppPagination
    v-if="totalElements && chunksize"
    @new-search="page = $event - 1"
    :totalElements="Number(totalElements)"
    :chunksize="chunksize"
    :page="tablePage"
    @update:page="changePage"
  />
</template>
<script>
import { onMounted, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import Icon from "../app-icon/icon.vue";
import AppPagination from "../app-pagination/pagination.vue";
export default {
  name: "AppTable",
  components: { Icon, AppPagination },
  props: [
    "tableHeaders",
    "items",
    "page",
    "totalElements",
    "chunksize",
    "width",
    "selected",
    "attr",
  ],
  setup(props, { emit }) {
    const tablePage = ref(props.page);
    const { tm } = useI18n();

    onMounted(() => {
      let index = props.items.findIndex(
        (el) => el[props.attr] === props.selected
      );
      if (index > 0) selectOne(props.items[index], index);
    });

    const changePage = (newPage) => {
      emit("update:page", newPage);
      tablePage.value = newPage;
    };

    function getRowValue(header, item) {
      if (
        (item[header.key] || item[header.key] > -1) &&
        item[header.key] !== ""
      ) {
        var field = item[header.key];
        if (Array.isArray(field)) {
          var aux = "";
          field.forEach((el) => (aux += el + "</br>"));
          return aux;
        } else {
          if (header.translate)
            return tm(`table.translate.${header.translate}.${field}`);
          else return field;
        }
      }
    }
    function selectOne(item, index) {
      if (index >= 0) {
        let lines = document.getElementsByClassName("line-table");
        for (let i = 0; i < lines.length; i++) {
          lines[i].classList.remove("selected");
        }
        lines[index].classList.add("selected");
        emit("update:selected", { typeId: item.id, type: item.type });
      }
    }

    return {
      changePage,
      tablePage,
      getRowValue,
      selectOne,
    };
  },
};
</script>

<style lang="scss">
@import "./app-table.scss";
</style>
