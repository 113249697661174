import colors from '../assets/styles/colors'

// SET CONSTANTS FOR COLOURS
document.documentElement.style.setProperty('--mq-primary', colors.$violet);
document.documentElement.style.setProperty('--mq-primary-light', colors.$lightViolet);
document.documentElement.style.setProperty('--mq-primary-dark', colors.$blackGreen);
document.documentElement.style.setProperty('--mq-secondary', colors.$red);
document.documentElement.style.setProperty('--mq-secondary-light', colors.$lightRed);
document.documentElement.style.setProperty('--mq-secondary-dark', colors.$neonPink);
document.documentElement.style.setProperty('--mq-thirty', colors.$blue);
document.documentElement.style.setProperty('--mq-thirty-light', colors.$lightBlue);
document.documentElement.style.setProperty('--mq-white', colors.$white);
document.documentElement.style.setProperty('--mq-gray', colors.$gray);
document.documentElement.style.setProperty('--mq-gray-light', colors.$lightGray);
document.documentElement.style.setProperty('--mq-header', colors.$grey);

// EXPORT CONSTANTS FOR SERVICES
export default {
  REGEX_EMAIL:/^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  complaint: 'complaint',
  country: 'countries',
  user: 'user',
  notification: 'notification',
  entity: 'entity',
  kpi:'kpi',
  assets: 'assets/'
}
