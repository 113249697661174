import Service from "./services";
import constantURL from "../config/constant";
import store from "../store/index.js";
import jsPDF from "jspdf";
import { i18n } from "../main";
import router from "../router";

var services = new Service();

export const assets = async ({ input, apiField, lan }) => {
  let response;

  try {
    response = await services.get(`${constantURL[apiField]}?input=${input}&lan=${lan}`);
  } catch (error) {
    return error;
  }
  return response.data.content;
};

export const initializeStorage = async () => {
  await _checkMobile();
  await getEntity();
  await _getEntities();
  await _getTypes();
};

export const downloadReturn = ({ content }) => {
  const { t } = i18n.global;
  let language = "es-ES";
  if (localStorage.Lang === "cat") {
    language = "ca-CA";
  }
  const today = new Date().toLocaleDateString(language, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  let doc = new jsPDF();
  var img = new Image();
  img.src = require(`../assets/images/${store.getters[`Setting/logoState`]}`);
  doc.addImage(img, "png", 160, 10, 35, 10);
  doc.setFontSize(10);
  doc.text(t("docReceipt.first"), 20, 40, { maxWidth: 175 });
  doc
  .text(t("docReceipt.second"), 20, 50, { maxWidth: 175 })
  .setFont(undefined, "bold");
doc
  .text(today + ".", 102, 58.3, { maxWidth: 175 })
  .setFont(undefined, "normal");
  doc.text(t("docReceipt.third"), 20, 63, { maxWidth: 175 });
  doc.text(t("docReceipt.fourth"), 20, 94, { maxWidth: 175 });
  doc.text(t("docReceipt.fifth"), 20, 108, { maxWidth: 175 });
  doc
    .text(t("docReceipt.sixth"), 20, 125, { maxWidth: 175 })
    .setFont(undefined, "bold");
  doc
    .text(content.trackNumber, 60, 140, { maxWidth: 175 })
    .setFont(undefined, "normal");
  doc.text(t("docReceipt.seventh"), 20, 175, { maxWidth: 175 });
  doc.save(t("docReceipt.eighth"));
};

export const response = (statusResponse, route, popup, emit) => {
  if (statusResponse.toString().startsWith(2)) {
    if (route) router.push(route);
    else {
      emit("popup", `${popup}Succeed`);
    }
  } else {
    emit("popup", `${popup}Failed`);
  }
};

 export const getEntity = async () => {
  try {
    let response = await services.get(
      `${constantURL.entity}/${process.env.VUE_APP_ENTITY_ID}`
    );
    await store.dispatch(`Setting/setEntity`, response.data);
  } catch (error) {
    console.log(error.response);
  }
};

const _getEntities = async () => {
  let response;

  try {
    response = await services.get(constantURL.entity);
    response.data.content.forEach((el) => {
      el["id"] = `${el.entityId}`;
    });
    await store.dispatch(`Setting/setEntities`, response.data.content);
  } catch (error) {
    return error.response;
  }
};

const _checkMobile = async () => {
  const ua = navigator.userAgent;
  if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    await store.dispatch(`Setting/mobileDataAction`, true);
  }
};

const _getTypes = async () => {
  const { t } = i18n.global;

  await store.dispatch(
    "Setting/translateTypes",
    [...Array(12)].map((_, i) => {
      return {
        id: i + 1,
        type: t(`app.complaintTypes[${i + 1}].type`),
        description: t(`app.complaintTypes[${i + 1}].description`),
      };
    })
  );
};
