import axios from "./index";

export default class servicesResource {
  get(url) {
    return axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("authToken"),
        "Accesss-Control-Allow-Origin": "*",
      },
    });
  }

  post(url, object) {
    return axios.post(url, object, {
      headers: {
        Authorization: sessionStorage.getItem("authToken"),
        "Accesss-Control-Allow-Origin": "*",
      },
    });
  }

  patch(url, object) {
    return axios.patch(url, object, {
      headers: {
        Authorization: sessionStorage.getItem("authToken"),
        "Accesss-Control-Allow-Origin": "*",
      },
    });
  }

  put(url, object) {
    return axios.put(url, object, {
      headers: {
        Authorization: sessionStorage.getItem("authToken"),
        "Accesss-Control-Allow-Origin": "*",
      },
    });
  }

  delete(url) {
    return axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("authToken"),
        "Accesss-Control-Allow-Origin": "*",
      },
    });
  }
}
