<template>
  <div
    id="dropzone"
    style="visibility: hidden; opacity: 0"
    @click="openFiles()"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
    :class="{ highLight: highLight }"
  >
    <div id="textnode">
      <p><i class="icon fa fa-upload"></i></p>
      <p v-html="$t(`app.documentMessage`)" />
    </div>
    <input
      ref="fileInput"
      class="file-input"
      type="file"
      multiple
      @change="$emit('filesAdded', $event.target.files)"
    />
    <slot name="message"></slot>
  </div>
</template>
<script>
import { onMounted, ref } from "@vue/runtime-core";

export default {
  name: "DragAndDrop",
  props: ["disabled"],
  setup(props, { emit }) {
    const fileInput = ref(null);
    let highLight = ref(null);

    onMounted(() => {
      window.addEventListener("dragenter", function (e) {
        document.querySelector("#dropzone").style.visibility = "";
        document.querySelector("#dropzone").style.opacity = 1;
        document.querySelector("#textnode").style.fontSize = "48px";
      });

      window.addEventListener("dragleave", function (e) {
        e.preventDefault();

        document.querySelector("#dropzone").style.visibility = "hidden";
        document.querySelector("#dropzone").style.opacity = 0;
        document.querySelector("#textnode").style.fontSize = "42px";
      });

      window.addEventListener("dragover", function (e) {
        e.preventDefault();
        document.querySelector("#dropzone").style.visibility = "";
        document.querySelector("#dropzone").style.opacity = 1;
        document.querySelector("#textnode").style.fontSize = "48px";
      });

      window.addEventListener("drop", function (e) {
        e.preventDefault();
        document.querySelector("#dropzone").style.visibility = "hidden";
        document.querySelector("#dropzone").style.opacity = 0;
        document.querySelector("#textnode").style.fontSize = "42px";
      });
    });

    function openFiles() {
      if (props.disabled) return;
      fileInput.value.click();
    }

    function onDragOver(event) {
      if (props.disabled) return;
      event.preventDefault();
      highLight.value = true;
    }
    function onDragLeave(event) {
      highLight.value = false;
    }

    function onDrop(event) {
      if (props.disabled) return;
      event.preventDefault();
      const files = event.dataTransfer.files;
      let result = [];
      for (var i = 0; i < files.length; i++) {
        result.push(files[i]);
      }
      emit("filesAdded", result);
      highLight.value = false;
    }

    return {
      fileInput,
      onDragOver,
      onDragLeave,
      onDrop,
      openFiles,
      highLight,
    };
  },
};
</script>
<style lang="scss">
@import "drag-and-drop.scss";
</style>
