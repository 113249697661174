<template>
  <transition name="modal">
    <div class="modal-mask" v-if="showModal">
      <div class="modal-wrapper">
        <section
          class="modal-container"
          :style="{ 'min-width': width ? width + 'vw' : 'auto' }"
        >
          <div class="modal-header" v-if="header">
            <slot name="header" />
          </div>
          <div
            class="modal-body"
            :style="{
              height: height ? height + 'vh' : 'auto',
              'text-align': 'left',
            }"
          >
            <slot name="body" />
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <AppButton
                @click="$emit('close')"
                :title="footerButton"
                :color="{ color: 'primary', hover: 'secondary' }"
              />
            </slot>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>
<script>
import AppButton from "../app-button/button.vue";
import { onMounted } from "vue";

export default {
  name: "Modal",
  props: {
    header: { type: Boolean, default: true },
    footer: { type: Boolean, default: true },
    width: { type: Number },
    height: { type: Number },
    showModal: { default: false },
    footerButton: { default: "accept" },
  },
  components: { AppButton },
  setup(props, { emit }) {
    onMounted(() => {
      //  window.onclick = closeModal
    });

    function closeModal(event) {
      var modal = document.getElementsByClassName("modal-wrapper")[0];
      if (event.target === modal) emit("close");
    }
  },
};
</script>
<style lang="scss">
@import "modal.scss";
</style>
