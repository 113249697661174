<template>
  <section class="resolution-section">
    <h3 v-html="$t('form.complaints.complaintData')" />
    <section class="view-complaint" @click="callback('view')">
      <Icon type="folder" :width="2.5" color="primary" />
      <p v-html="$t('app.complaintInfo')" />
    </section>
    <div
      v-if="user && user.role && (status == 2 || status == 4)"
      class="button-section"
      @click="
        $emit('popup', {
          title: 'continue',
          body: `${$t('modal.resolveMessage')}`,
          button: () => callback('solve'),
        })
      "
    >
      <Icon
        class="close-complaint"
        type="documentCheck"
        :width="3"
        color="white"
      />
      <p v-html="$t('app.closeComplaint')" />
    </div>
    <div class="button-section" v-if="status">
      {{ $t("app.statusComplaint") }}
      <p
        :class="['status', `status${status}`]"
        v-html="$t(`app.status.${status}`).toUpperCase()"
      />
    </div>
    <section class="dialog-frame" style="">
      <div
        v-html="$t('app.welcome-message')"
        style="margin-bottom: 1vh"
        :class="[
          'message',
          {
            'right-message': user && user.role,
            'left-message': !(user && user.role),
          },
        ]"
      />
      <section
        v-for="day in Object.keys(messages)"
        :key="day"
        class="messages-section"
      >
        <p v-html="moment(day).format('DD MMM YYYY')" class="day-messages" />
        <div
          v-for="message in messages[day]"
          :key="message.id"
          :style="{
            display: 'flex',
            flexDirection: !message.me ? 'row' : 'row-reverse',
            alignItems: 'flex-end',
          }"
        >
          <div
            v-html="message.messageText"
            :class="[
              'message',
              { 'right-message': message.me, 'left-message': !message.me },
            ]"
          />
          <p
            style="padding: 1vh 1vw"
            v-html="moment.utc(message.createdAt).tz(currentTimezone).format('HH:mm')"
            
          />
        </div>
      </section>
    </section>
    <AppTextarea attr="id" ref="message" />
    <AppButton
      title="send"
      @click="callback('send')"
      :color="{ color: 'primary', hover: 'secondary' }"
    />
  </section>
</template>

<script>
import AppButton from "@/components/common/app-button/button.vue";
import { ref, onMounted, computed } from "vue";
import {
  changeStatus,
  getAllMessages,
  sendMessage,
} from "@/services/complaint";
import AppTextarea from "../../../components/common/app-textarea/textarea.vue";
import Icon from "@/components/common/app-icon/icon.vue";
import { useRouter } from "vue-router";
import moment from "moment";
import { useStore } from "vuex";
import { getComplaintById } from "../../../services/complaint";
import { response } from "../../../services/general";

export default {
  name: "Init",
  components: { AppTextarea, AppButton, Icon },
  setup(_, { emit }) {
    const router = useRouter();
    const store = useStore();
    const user = JSON.parse(sessionStorage.getItem("userConnected"));
    const messages = computed(() => store.getters[`Setting/getMessages`]);
    let message = ref(null);
    const status = computed(() => store.getters[`Setting/getComplaintState`]);
    const complaintId = router.currentRoute.value.params.id;
    const currentTimezone = moment.tz.guess();
    onMounted(async () => {
      getComplaintById({ complaintId, password: !(user && user.role) });
      await getAllMessages({ complaintId });
      scrollToBottom();
    });

    const scrollToBottom = () => {
      let messagesContainer =
        document.getElementsByClassName("dialog-frame")[0];
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    };

    const callback = async (action, payload) => {
      switch (action) {
        case "view":
          if (user && user.role)
            router.push(`/admin/complaint/${complaintId}/assignation`);
          else router.push(`${router.currentRoute.value.path}/complaint-data`);
          break;
        case "goTo":
          router.push(payload);
          break;
        case "send":
          await sendMessage({
            complaintId,
            message: message.value.textValue,
          });
          message.value.clearTextarea();
          scrollToBottom();
          break;
        case "solve":
          var status = await changeStatus({
            complaintId,
            status: "3",
          });
          response(status, "/admin/complaint", "solve", emit);
          break;
      }
    };

    return {
      message,
      messages,
      callback,
      user,
      status,
      moment: moment,
      currentTimezone,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./complaint.scss";
</style>
