<template>
  <section class="main-view">
    <AppButton
      class="button-create"
      title="create"
      @click="callback('show', {})"
      :color="{ color: 'primary', hover: 'secondary' }"
    />
    <section class="filter-section">
      <AppSelect
        class="role-filter"
        v-if="userConnected.role == 'ADMIN'"
        placeholder="selectOne"
        title="user.role"
        attr="id"
        :options="rolesAdmin"
        v-model:value="filter['role']"
      />
      <AppSelect
        class="role-filter"
        v-if="userConnected.role == 'ADMINENTITY'"
        placeholder="selectOne"
        title="user.role"
        attr="id"
        :options="roles2"
        v-model:value="filter['role']"
      />
      <AppSelect
        class="client-filter"
        v-if="userConnected.role == 'ADMIN'"
        placeholder="selectOne"
        title="user.entities"
        attr="entity"
        :options="entities"
        v-model:value="filter['entityId']"
      />
      <section class="button-filter">
        <AppButton
          title="filter"
          @click="callback('filter')"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
        <AppButton
          title="remove"
          @click="callback('remove')"
          :color="{ color: 'secondary', hover: 'secondary' }"
        />
      </section>
    </section>
    <section v-if="!isMobile" class="table-init">
      <AppTable
        :tableHeaders="tableHeaders"
        :totalElements="totalElements"
        :chunksize="chunksize"
        v-model:page="page"
        v-model:items="users"
        @clickOne="callback('show2', $event)"
      />
    </section>
    <AppItems v-else v-model:items="users" v-model:page="page" />
    <AppModal
      :showModal="showModal[0]"
      @close="
        showModal[0] = false;
        error = false;
      "
      :width="45"
      :height="45"
    >
      <template #header><h2 v-html="$t(`modal.${title}`)" /></template>
      <template #body>
        <AppSelect
          v-if="userConnected.role == 'ADMIN'"
          placeholder="selectOne"
          title="user.role"
          attr="id"
          :options="rolesAdmin"
          v-model:value="request['role']"
          @update:value="error = false"
          :error="error && !request.role"
        />
        <AppSelect
          v-if="userConnected.role == 'ADMINENTITY'"
          placeholder="selectOne"
          title="user.role"
          attr="id"
          :options="rolesEntity"
          v-model:value="request['role']"
          @update:value="error = false"
          :error="error && !request.role"
        />
        <AppMultiSelect
          style="margin-top: 2vh"
          v-if="
            request['role'] &&
            request.role != 3 &&
            request.role != 4 &&
            request.role != 5
          "
          :error="error && (!request.entities || request.entities.length == 0)"
          placeholder="selectOne"
          title="user.entities"
          attr="entityId"
          name="entity"
          :options="entities"
          v-model:value="request['entities']"
        />
        <section class="modal-body-user" v-if="request['role']">
          <AppInput
            v-if="request.role != 3 && request.role != 4"
            :error="error && !request.name"
            title="user.name"
            v-model:value="request['name']"
          />
          <AppInput
            v-if="request.role != 3 && request.role != 4"
            :error="error && !request.firstFamilyName"
            title="user.firstFamilyName"
            v-model:value="request['firstFamilyName']"
          />
          <AppInput
            v-if="request.role == 3 || request.role == 4"
            :error="error && !request.entity"
            title="user.entity"
            v-model:value="request['entity']"
          />
          <AppInput
            title="user.email"
            :error="error && (!request.email || validateEmail)"
            v-model:value="request['email']"
          />
          <AppInput
            v-if="request.role != 3 && request.role != 4"
            :error="error && (!request.telephone || validateTelefone)"
            title="user.telephone"
            v-model:value="request['telephone']"
          />
          <!--<AppAutocomplete v-if="request.role==roles.length" placeholder="selectOne" title="user.country" apiField="country" v-model:value="request['country']"/>-->
          <AppMultiInput
            v-if="request.role == 3 || request.role == 4"
            :error="
              error &&
              (!request.sites || (request.sites && request.sites.length === 0))
            "
            placeholder="writeSite"
            title="user.sites"
            attr="id"
            v-model:value="request['sites']"
          />
          <!--<AppButton  v-if="request.role==roles.length" title="attach" type="upload" @click="callback('filter')" :color="{color:'thirty',hover:'thirty'}"/>-->
        </section>
      </template>
      <template #footer>
        <AppButton
          :title="title"
          @click="callback('create', '/home/complaint')"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
        <AppButton
          title="cancel"
          @click="
            showModal[0] = false;
            error = false;
          "
          :color="{ color: 'primary', hover: 'secondary' }"
        />
      </template>
    </AppModal>
    <AppModal
      :showModal="showModal[1]"
      @close="
        showModal[1] = false;
        error = false;
      "
      :width="45"
      :height="45"
    >
      <template #header><h2 v-html="$t(`modal.${title}`)" /></template>
      <template #body>
        <AppMultiSelect
          style="margin-top: 2vh"
          v-if="
            request['role'] &&
            request.role != 3 &&
            request.role != 4 &&
            request.role != 5
          "
          :error="error && (!request.entities || request.entities.length == 0)"
          placeholder="selectOne"
          title="user.entities"
          attr="entityId"
          name="entity"
          :options="entities"
          v-model:value="request['entities']"
        />
        <section class="modal-body-user" v-if="request['role']">
          <AppInput
            v-if="request.role != 3 && request.role != 4"
            :error="error && !request.name"
            title="user.name"
            v-model:value="request['name']"
          />
          <AppInput
            v-if="request.role != 3 && request.role != 4"
            :error="error && !request.firstFamilyName"
            title="user.firstFamilyName"
            v-model:value="request['firstFamilyName']"
          />
          <!--<AppInput title="user.email" :error="error&&!request.email" v-model:value="request['email']"/>-->
          <AppInput
            v-if="request.role != 3 && request.role != 4"
            :error="error && (!request.telephone || validateTelefone)"
            title="user.telephone"
            v-model:value="request['telephone']"
          />
          <!--<AppAutocomplete v-if="request.role==roles.length" placeholder="selectOne" title="user.country" apiField="country" v-model:value="request['country']"/>-->
          <AppMultiInput
            v-if="request.role == 3 || request.role == 4"
            :error="
              error &&
              (!request.entities ||
                (request.entities && request.entities.length === 0))
            "
            placeholder="writeSite"
            title="user.sites"
            attr="id"
            v-model:value="request.entities[0].sites"
          />
          <!--<AppButton  v-if="request.role==roles.length" title="attach" type="upload" @click="callback('filter')" :color="{color:'thirty',hover:'thirty'}"/>-->
        </section>
      </template>
      <template #footer>
        <AppButton
          title="delete"
          v-if="(request.role === 2 && userConnected.role === 'ADMIN') || (request.role === 5 && userConnected.role === 'ADMINENTITY')"
          @click="callback('delete', '/home/complaint')"
          :color="{ color: 'secondary', hover: 'secondary' }"
        />
        <AppButton
          :title="title"
          @click="callback('create', '/home/complaint')"
          :color="{ color: 'primary', hover: 'secondary' }"
        />
        <AppButton
          title="cancel"
          @click="
            showModal[1] = false;
            error = false;
          "
          :color="{ color: 'primary', hover: 'secondary' }"
        />
      </template>
    </AppModal>
  </section>
</template>

<script>
import AppTable from "../../components/common/app-paginated-table/app-table.vue";
import AppItems from "../../components/common/app-items/items.vue";
import AppSelect from "../../components/common/app-select/select.vue";
import AppMultiSelect from "../../components/common/app-multiselect/multiselect.vue";
import AppMultiInput from "../../components/common/app-multi-input/multi-input.vue";
import AppAutocomplete from "../../components/common/app-autocomplete/autocomplete.vue";
import AppButton from "../../components/common/app-button/button.vue";
import AppModal from "../../components/common/modal/modal.vue";
import AppInput from "../../components/common/app-input/input.vue";
import { computed, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { getAllUsers, createUpdateUser, deleteUser } from "../../services/user";
import { useI18n } from "vue-i18n";

export default {
  name: "Init",
  components: {
    AppButton,
    AppModal,
    AppTable,
    AppItems,
    AppInput,
    AppSelect,
    AppMultiSelect,
    AppMultiInput,
    AppAutocomplete,
  },
  setup() {
    const store = useStore();
    const { tm } = useI18n();
    const roles = computed(() => store.getters[`Setting/roleState`]);
    const rolesTraduction = [];
    roles.value.forEach((role) => {
      rolesTraduction.push({
        id: role.id,
        name: tm(`table.translate.roles.${role.id}`),
      });
    });
    const rolesAdmin = rolesTraduction;
    const rolesEntity = rolesAdmin.splice(4, 1);
    const roles2 = [
      { name: tm("table.translate.roles.4"), id: 4 },
      { name: tm("table.translate.roles.5"), id: 5 },
    ];
    const entities = computed(() => store.getters[`Setting/getEntities`]);
    let users = computed(() => store.getters[`Setting/getUsers`]);
    let error = ref(false);
    const totalElements = computed(
      () => store.getters[`Setting/getTotalElements`]
    );
    const chunksize = ref(10);
    let page = ref(0);
    let filter = ref({});
    let filterString = ref("");
    let userConnected = computed(() =>
      JSON.parse(sessionStorage.getItem("userConnected"))
    );

    let request = ref({});
    let validateEmail = ref(false);
    let validateTelefone = ref(false);
    let showModal = ref([false, false]);
    let title = ref("create");

    onMounted(async () => await getUsers());

    const getUsers = async () => {
      await getAllUsers({
        filter: filterString.value,
        offset: page.value,
        limit: chunksize.value,
      });
    };

    watch(
      () => page.value,
      () => getUsers()
    );
    async function callback(action, user) {
      error.value = false;
      validateEmail.value = false;
      validateTelefone.value = false;
      switch (action) {
        case "show":
          request.value = user;
          title.value = Object.keys(user).length > 0 ? "modify" : "create";
          showModal.value[0] = true;
          break;
        case "show2":
          request.value = user;
          title.value = Object.keys(user).length > 0 ? "modify" : "create";
          showModal.value[1] = true;
          
          break;
        case "create":
          if (
            !request.value.role ||
            ((request.value.role == 3 || request.value.role == 4) &&
              Object.keys(request.value).length < 3)
          ) {
            error.value = true;
          } else if (
            !request.value.role ||
            (request.value.role != 3 &&
              request.value.role != 4 &&
              Object.keys(request.value).length < 5)
          ) {
            error.value = true;
          }
          if (
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            request.value.email
            ) === false
          ) {
              error.value = true;
              validateEmail.value = true;
          }
          if ( request.value.role != 3 &&
              request.value.role != 4 &&
            /^\d{9}$/.test(request.value.telephone) === false 
          ) {
            error.value = true;
            validateTelefone.value = true;
          }
          if (!error.value) {
            if (request.value.role == 3 || request.value.role == 4) {
              if (title.value === "create") {
                request.value["entities"] = [
                  { entity: request.value.entity, sites: request.value.sites },
                ];
                delete request.value.entity;
                delete request.value.sites;
              }
            }
            const response = await createUpdateUser({
              request: request.value,
              id: title.value === "modify" ? request.value.email : null,
            });
            if (response.toString().startsWith(2)) {
              showModal.value[0] = false;
              showModal.value[1] = false;
              getUsers();
            }
          }
          break;
        case "filter":
          let aux = Object.keys(filter.value).map((el) => {
            return !!filter.value[el]
              ? el === "role"
                ? `&${el}[]=${filter.value[el]}`
                : `&${el}=${filter.value[el]}`
              : "";
          });
          filterString.value = aux.join("");
          getUsers();
          break;
        case "remove":
          filter.value = {};
          filterString.value = "";
          getUsers();
          break;
        case "delete":
          const response = await deleteUser(request.value.email);
          if (response.toString().startsWith(2)) {
            showModal.value[0] = false;
            showModal.value[1] = false;
            getUsers();
          }
          break;
      }
    }

    return {
      roles,
      validateEmail,
      validateTelefone,
      rolesTraduction,
      rolesAdmin,
      rolesEntity,
      roles2,
      filter,
      error,
      users,
      showModal,
      request,
      totalElements,
      userConnected,
      page,
      chunksize,
      title,
      isMobile: computed(() => store.getters[`Setting/mobileState`]),
      callback,
      entities,
      tableHeaders: [
        { key: "fullname", class: 1 },
        { key: "email", class: 1 },
        { key: "role", class: 1, translate: "roles" },
        { key: "clients", class: 2 },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.button-create {
  align-self: flex-start;
  @media all and (max-width: 500px) {
    align-self: center;
  }
}
.filter-section {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  flex-wrap: wrap;
  width: 42vw;
  gap: 15px;
  justify-content: flex-end;
  align-items: baseline;
  .role-filter {
    flex-grow: 1;
    flex-basis: 12vw;
  }
  .client-filter {
    flex-grow: 3;
    flex-basis: 18vw;
  }
  .button-filter {
    flex-grow: 1;
    flex-basis: 7vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    row-gap: 1vh;
  }
  @media all and (max-width: 500px) {
    width: 100%;
    padding: 0 5vw;
    gap: 10px;

    .role-filter,
    .client-filter,
    .button-filter {
      flex-grow: 1 !important;
      flex-basis: 100%;
      flex-direction: row;
    }
  }
}
.table-init {
  align-self: center !important;
  width: 70vw;
  @media all and (max-width: 500px) {
    margin: 0 1vw 0 1vw;
  }
}
</style>
