<template>
  <section class="new-complaint">
    <ul class="sidebar-menu" v-if="!isMobile">
      <li
        v-for="(section, i) in Object.keys(form)"
        :key="section.id"
        v-html="i + 1 + '. ' + $t(`form.complaints.${section}`)"
        :class="{ 'current-section': selected == i }"
      />
    </ul>
    <section
      v-for="(section, i) in Object.keys(form)"
      :key="section.id"
      style="background-color: var(--mq-white)"
    >
      <transition name="fade">
        <AppFormSection
          v-if="selected == i"
          class="section-complaint"
          :title="section"
          :index="i + 1"
          @page="selected += Number($event)"
          @send="sendComplaint"
          :totalSections="Object.keys(form).length"
          :section="form[section]"
        />
      </transition>
    </section>
  </section>
</template>

<script>
import AppFormSection from "@/components/common/app-form-section/form-section.vue";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { createComplaint } from "@/services/complaint";
import { response, getEntity } from "@/services/general";
import AppButton from "@/components/common/app-button/button.vue";

export default {
  name: "NewComplaint",
  components: { AppFormSection, AppButton },
  setup(_, { emit }) {
    const store = useStore();
    let form = computed(() => store.getters[`Setting/newComplaintState`]);
    let entity = computed(() => store.getters[`Setting/getEntity`]);
    let selected = ref(0);

    onMounted(async () => {
      if (entity.value === undefined){
        await getEntity();
      }
      await store.dispatch(`Setting/setComplaint`, entity.value);
    });

    const sendComplaint = async () => {
      const payload = store.getters[`Setting/getComplaint`];
      const responseComplaint = await createComplaint({ request: payload });
      response(
        responseComplaint.status,
        {
          path: "/home/new-complaint/sum-code",
          query: { id: responseComplaint.data.complaintId },
        },
        "complaint",
        emit
      );
    };

    return {
      form,
      sendComplaint,
      selected,
      isMobile: store.getters[`Setting/mobileState`],
    };
  },
};
</script>

<style lang="scss">
@import "./home.scss";
</style>
