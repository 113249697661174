<template>
  <section class="assignation-section">
    <AppMultiselect
      v-if="user && (user.role === 'ADMIN' || user.role === 'ADMINENTITY')"
      placeholder="selectOne"
      :disabled="status > 2 && status != 4"
      :title="titleManagerAsignation"
      attr="email"
      name="username"
      :options="managers"
      :canRemove="status === 1"
      :originalOptions="originalSelectedUsers"
      v-model:value="complaintSelectedUsers"
    />
    <AppButton
      v-if="
        user &&
        (user.role === 'ADMIN' || user.role === 'ADMINENTITY') &&
        (status <= 2 || status == 4)
      "
      title="assign"
      @click="callback('assign')"
      :color="{ color: 'primary', hover: 'secondary' }"
    />
    <section
      v-if="user && (user.role === 'ENTITY' || user.role === 'ADMINENTITY')"
      style="
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 2vw;
      "
    >
      <div
        class="button-section"
        @click="callback('reopen')"
        v-if="status == 3"
      >
        <Icon
          class="close-complaint"
          type="documentCheck"
          :width="3"
          color="white"
        />
        <p v-html="$t('app.reopen')" />
      </div>
      <div
        class="button-section"
        @click="callback('close')"
        v-if="status == 3 || status == 4"
      >
        <Icon
          class="close-complaint"
          style="background-color: var(--mq-primary)"
          type="close"
          :width="3"
          color="white"
        />
        <p v-html="$t('app.solveComplaint')" />
      </div>
    </section>
  </section>
  <AppFormSection
    v-for="(section, i) in Object.keys(form)"
    :key="section.id"
    :index="i + 1"
    :title="section"
    :section="form[section]"
  />
  <AppButton
    v-if="status !== 3 && status !== 5 && !user"
    style="margin: 0 auto 2vh auto"
    title="update"
    @click="callback('update')"
    :color="{ color: 'primary', hover: 'secondary' }"
  />
</template>

<script>
import AppFormSection from "@/components/common/app-form-section/form-section.vue";
import AppMultiselect from "@/components/common/app-multiselect/multiselect.vue";
import AppButton from "@/components/common/app-button/button.vue";
import { computed, ref, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";
import { getManagers } from "@/services/user";
import {
  getComplaintById,
  updateAttachments,
  changeStatus,
  assignManagers,
} from "@/services/complaint";
import { response } from "@/services/general";
import { useRouter } from "vue-router";
import Icon from "@/components/common/app-icon/icon.vue";

export default {
  name: "Init",
  components: { AppMultiselect, AppFormSection, AppButton, Icon },
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();
    let managers = ref([]);
    let complaintSelectedUsers = ref([]);
    let originalSelectedUsers = ref([]);
    let complaint = computed(() => store.getters[`Setting/getComplaint`]);
    let form = computed(() => store.getters[`Setting/complaintSumState`]);
    const user = JSON.parse(sessionStorage.getItem("userConnected"));
    const complaintId = router.currentRoute.value.params.id;
    const status = computed(() => store.getters[`Setting/getComplaintState`]);
    let titleManagerAsignation = "assignManager";

    onBeforeMount(async () => {
      await getComplaintById({
        complaintId,
        password: !(user && user.role),
      });
      
      if (user && user.role === "ADMIN") {
        managers.value = await getManagers(
          { entityId: complaint.value.entityId },
          1
        );
      } else if (user && user.role === "ADMINENTITY") {
        managers.value = await getManagers(
          { entityId: complaint.value.entityId },
          4
        );
      }

      complaintSelectedUsers.value = [...complaint.value['users']];
      originalSelectedUsers.value = [...complaint.value['users']];
    });

    watch(() => {
      if(status.value !=1)
       titleManagerAsignation = "managerAssigned";
    });

    const callback = async (action) => {

      let status;
      switch (action) {
        case "assign":
          status = await assignManagers({ users: complaintSelectedUsers.value, complaintId: complaint.value.complaintId });
          response(status, "/admin/complaint", "assignation", emit);
          break;
        case "update":
          complaint.value = store.getters[`Setting/getComplaint`];
          status = await updateAttachments({
            complaintId,
            documents: complaint.value.documents,
          });
          response(status, null, "upload", emit);
          break;
        case "reopen":
          status = await changeStatus({
            complaintId,
            status: "4",
          });
          response(status, "/admin/init", "reopen", emit);
          break;
        case "close":
          status = await changeStatus({
            complaintId,
            status: "5",
          });
          response(status, "/admin/init", "close", emit);
          break;
      }
    };
    
    return {
      managers,
      callback,
      form,
      complaint,
      complaintSelectedUsers,
      originalSelectedUsers,
      status,
      router,
      user,
      titleManagerAsignation,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./complaint.scss";
</style>
